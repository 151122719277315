import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Container } from "./styles";
import ModalRecoverPassword from "../../../components/ModalRecoverPassword";
import { useAuth } from "../../../contexts/AuthContext";
import Logo1 from "../../../assets/images/logo/LOGO - LUMEN PRO - AZUL.png";
import { COMPANY_EMAIL, SITE_NAME } from "../../../config";

export default function Login() {
  const { signIn } = useAuth();
  const [recoverPassword, setRecoverPassword] = useState(false);

  const [data, setData] = useState({
    email: "",
    password: "",
    remember: false,
  });

  const handleLogin = (event) => {
    event.preventDefault();

    const requestBody = {
      email: data.email,
      password: data.password,
    };

    signIn(requestBody, data.remember);
  };

  return (
    <Container>
      <div className='div__gradient'>
        <form onSubmit={handleLogin}>
          <img
            alt={`Logo ${SITE_NAME}`}
            src={Logo1}
            title={`Logo ${SITE_NAME}`}
            className='img__logo_mobile'
          />
          <h1>Administrativo</h1>
          <span>Realize login para entrar no sistema</span>
          <input
            className='input-custom-style'
            type='text'
            placeholder='E-mail'
            autoFocus
            onChange={(e) => setData({ ...data, email: e.target.value })}
          />
          <input
            className='input-custom-style'
            type='password'
            placeholder='Senha'
            onChange={(e) => setData({ ...data, password: e.target.value })}
          />
          <div className='div__functions'>
            <label>
              <input
                className='input-custom-style'
                type='checkbox'
                checked={data.remember}
                onChange={() => setData({ ...data, remember: !data.remember })}
              />
              Lembrar-me
            </label>
            <a
              style={{ cursor: "pointer" }}
              onClick={() => setRecoverPassword(true)}
            >
              Recuperar senha
            </a>
          </div>
          <button className='btn__login' type='submit'>
            Login
          </button>
          <div className='div__line' />
          <div className='div__subtext'>
            <label>
              Novo por aqui? Envie mensagem a equipe responsável e solicite seu
              acessso
            </label>
          </div>
          <Link to='/cadastro' style={{ width: "100%" }}>
            <button
              className='btn__register'
              type='button'
              onClick={() => window.open(`mailto:${COMPANY_EMAIL}`)}
            >
              Solicitar acesso
            </button>
          </Link>
        </form>
      </div>
      {recoverPassword && (
        <ModalRecoverPassword fechar={() => setRecoverPassword(false)} />
      )}
    </Container>
  );
}
