export const API_URL = process.env.REACT_APP_API_URL;

export const SITE_NAME = 'Lumen Pro Administrativo';
export const COMPANY_EMAIL = 'projetos@lumenproengenharia.com.br';

export const listetapas = [
  '1 - Análise de documentos pela equipe',
  '2 - Projeto em elaboração',
  '3 - Em análise na concessionária de energia',
  '4 - Impedimentos',
  '5 - Projeto aprovado',
  '6 - Vistoria solicitada',
  // '7 - Projeto reprovado',
];

export const listStates = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
];
