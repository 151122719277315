import styled from "styled-components";

export const Container = styled.div`
  .div__gradient {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    background-image: linear-gradient(
      to right bottom,
      rgba(var(--primary2_rgb), 0.9) 40%,
      rgba(var(--primary2_rgb), 0.8)
    );
    width: 100%;
    height: 100%;
  }

  form {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    width: 100%;
    min-height: 50%;

    box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.1);
    padding: 2rem 1rem;
    margin: auto;

    img {
      width: 100%;
      max-width: 250px;
    }

    h1 {
      font-weight: 600;
      margin: 0.5rem 0rem;
      color: var(--primary2);
    }
    span {
      font-size: 0.8rem;
      max-width: 280px;
      color: rgba(var(--black_rgb), 0.7);
      margin-bottom: 1rem;
    }

    .div__functions {
      display: flex;
      width: 100%;
      max-width: 320px;
      justify-content: space-between;

      label,
      a {
        display: flex;
        font-size: 0.8rem;
        justify-content: center;
        align-items: center;
        color: rgba(var(--black_rgb), 0.7);

        input {
          width: 15px;
          margin: 0rem;
          margin-right: 0.2rem;
        }
      }

      a {
        text-decoration: none;
        :hover {
          text-decoration: underline;
        }
      }
    }
    .btn__login {
      font-family: "Poppins", sans-serif;
      text-transform: uppercase;
      width: 100%;
      max-width: 320px;
      height: 45px;
      background-color: var(--primary2);
      border: 0px;
      border-radius: var(--border-radius);
      color: var(--white);
      margin-top: 1rem;
      margin-bottom: 0.75rem;
      cursor: pointer;
      :hover,
      :focus {
        box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.4);
        filter: brightness(1.4);
      }
    }
    .div__subtext {
      display: flex;
      width: 100%;
      max-width: 320px;
      label {
        display: flex;
        font-size: 0.8rem;
        text-align: center;
        margin: 0.5rem auto;
        justify-content: center;
        align-items: center;
        color: rgba(var(--black_rgb), 0.7);
      }
    }
    .div__line {
      width: 100%;
      border-bottom: 1px solid rgba(var(--black_rgb), 0.3);
      max-width: 320px;
      margin: 0.5rem;
    }
    .btn__register {
      font-family: "Poppins", sans-serif;
      text-transform: uppercase;
      width: 100%;
      max-width: 320px;
      height: 45px;
      background-color: transparent;
      border: 1px solid var(--primary2);
      border-radius: var(--border-radius);
      color: var(--primary2);
      /* margin-top: 1rem; */
      margin-bottom: 0.75rem;
      cursor: pointer;
      :hover,
      :focus {
        box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.2);
      }
    }
  }

  @media only screen and (max-width: 675px) {
    button {
      font-size: 1rem;
    }

    .div__part1 {
      display: none;
    }

    form {
      flex: 1;
      margin: 0rem;
      max-width: none;

      .img__logo_mobile {
        display: block;
        max-width: 250px;
        height: auto;
      }
      input[type="text"],
      input[type="password"] {
        padding: 1rem;
        font-size: 1rem;
      }
    }
  }
`;
