import { useEffect, useState } from 'react';
import { mask } from 'remask';
import { toast } from 'react-toastify';
import Dialogotrocarsenha from '../../../components/Dialogotrocarsenha';
import Dialogoalterardados from '../../../components/Dialogoalterardados';
import Dialogonewuser from '../../../components/Dialogonewuser';
import { BsSortAlphaDown, BsSortAlphaUpAlt } from 'react-icons/bs';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import Backdrop from '../../../components/Backdrop';
import Dialogoconfirmacao from '../../../components/Dialogoconfirmacao';
import {
  GET_USERS_COMPANY,
  DELETE_USER_COMPANY,
} from '../../../services/endpoints';
import api from '../../../services/api';
import { Container, Page, Card } from './styles';

import { SITE_NAME } from '../../../config';
import { useAuth } from '../../../contexts/AuthContext';

function Users(props) {
  const { user } = useAuth();
  const [showChangePasswordDialog, setShowChangePasswordDialog] = useState({
    open: false,
    dados: '',
  });
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showNewUserDialog, setNewUserDialog] = useState({
    open: false,
    edit: false,
    dados: '',
  });
  const [showaltdados, setShowaltdados] = useState(false);
  const [order, setOrder] = useState('');
  const [showDeleteUserDialogConf, setShowDeleteUserDialogConf] = useState({
    status: false,
    width: 'sm',
    title: 'Confirmar',
    description: 'Deseja realmente suspender este usuário?',
    btn_conf: 'Sim',
    btn_cancel: 'Não',
    delete: true,
    dados: '',
  });

  const [filter, setFilter] = useState({
    type: '',
    value: '',
    dig: '',
  });
  const [usersList, setUsersList] = useState([]);

  const handleFilter = (dado) => {
    // console.log(dado.cpf.replace(/[^0-9]/g, ''));

    if (filter.type === 'name') {
      if (dado.name.toLowerCase().indexOf(filter.value.toLowerCase()) > -1)
        return true;
    } else if (filter.type === 'cpf') {
      if (
        dado.cpf
          .replace(/[^0-9]/g, '')
          .indexOf(filter.value.replace(/[^0-9]/g, '')) > -1
      )
        return true;
    } else {
      return true;
    }
  };

  const handleFilterValue = (event) => {
    if (filter.type === 'cpf') {
      setFilter({
        ...filter,
        value: mask(event.target.value, '999.999.999-99'),
      });
    } else {
      setFilter({ ...filter, value: event.target.value });
    }
  };

  const handleChangePassword = async ({
    old_password,
    new_password,
    password_confirmation,
  }) => {
    setShowBackdrop(true);

    await api
      .put(`/users/admin/update-user/${showChangePasswordDialog.dados.id}`, {
        old_password,
        new_password,
        password_confirmation,
      })
      .then((res) => {
        toast.success(
          `Senha de ${showChangePasswordDialog?.dados?.name} alterada com sucesso.`
        );
        setShowChangePasswordDialog({
          open: false,
          dados: '',
        });
        setShowBackdrop(false);
      })
      .catch((error) => {
        toast.error('Erro ao alterar senha.');
        console.log(error.response);
        setShowBackdrop(false);
      });
  };

  let userListOrdered = usersList?.filter(handleFilter).sort((a, b) => {
    if (order === 'AZ') {
      return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
    } else if (order === 'ZA') {
      return a.name < b.name ? 1 : b.name < a.name ? -1 : 0;
    } else if (order === 'primeiros') {
      return new Date(b.created_at) - new Date(a.created_at);
    } else if (order === 'ultimos') {
      return new Date(a.created_at) - new Date(b.created_at);
    }
  });

  const refreshUserList = async () => {
    await GET_USERS_COMPANY(setUsersList, setShowBackdrop);
  };

  useEffect(() => {
    refreshUserList();
  }, []);

  return (
    <Container>
      <Page>
        <header>
          <h1>Usuários da {SITE_NAME}</h1>
          <span>
            Gerencie os usuários que terão acesso aos projetos de cada
            Integrador.
          </span>
        </header>
        <content>
          <div className="div__fieldFilter">
            <div className="div__filters">
              <select
                onChange={(event) =>
                  setFilter({
                    ...filter,
                    type: event.target.value,
                    value: '',
                    dig: '',
                  })
                }
                value={filter.type}
                style={{ maxWidth: 200 }}
              >
                <option value="" selected>
                  {' '}
                  Filtro{' '}
                </option>
                <option value="name">Nome</option>
                <option value="cpf">CPF</option>
              </select>

              {/* autocomplete */}

              <input
                placeholder={
                  filter.type === 'name'
                    ? 'Digite o nome'
                    : filter.type === 'cpf'
                    ? 'Digite o CPF'
                    : ' - '
                }
                value={filter.value}
                disabled={!filter.type ? true : false}
                onChange={handleFilterValue}
                style={{
                  display: filter.type === 'name' || filter.type === 'cpf',
                }}
              />

              <select
                required
                name="state"
                style={{
                  flex: 1,
                  display: filter.type === 'state' ? 'block' : 'none',
                }}
                value={filter.value}
                onChange={handleFilterValue}
              >
                <option value="" disabled selected>
                  Escolha o estado
                </option>
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AP">AP</option>
                <option value="AM">AM</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MT">MT</option>
                <option value="MS">MS</option>
                <option value="MG">MG</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PR">PR</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RS">RS</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="RC">SC</option>
                <option value="SP">SP</option>
                <option value="SE">SE</option>
                <option value="TO">TO</option>
              </select>

              {/* fim de autocomplete */}
            </div>
            <div className="div__order">
              <button
                className={order === 'AZ' ? 'btn__esq btn__ativ' : 'btn__esq'}
                onClick={() => (order === 'AZ' ? setOrder('') : setOrder('AZ'))}
              >
                <BsSortAlphaDown style={{ fontSize: '1.4rem' }} />
              </button>
              <button
                className={order === 'ZA' ? 'btn_meio btn__ativ' : 'btn_meio'}
                onClick={() => (order === 'ZA' ? setOrder('') : setOrder('ZA'))}
              >
                <BsSortAlphaUpAlt style={{ fontSize: '1.4rem' }} />
              </button>

              <button
                className={
                  order === 'primeiros' ? 'btn_meio btn__ativ' : 'btn_meio'
                }
                onClick={() =>
                  order === 'primeiros' ? setOrder('') : setOrder('primeiros')
                }
              >
                Recentes
              </button>
              <button
                className={
                  order === 'ultimos' ? 'btn__dir btn__ativ' : 'btn__dir'
                }
                onClick={() =>
                  order === 'ultimos' ? setOrder('') : setOrder('ultimos')
                }
              >
                Antigos
              </button>
            </div>
          </div>

          <div className="div__field_actions">
            <div className="div__fiel_filter"></div>
            <div className="conteudo">
              <button
                type="submit"
                className="btn__finish"
                onClick={() =>
                  setNewUserDialog({ ...showNewUserDialog, open: true })
                }
              >
                + Novo Usuário
              </button>
            </div>
          </div>

          <div className="div__deck">
            <div className="head_list">
              <div className="div__name" style={{ flex: 2 }}>
                Nome
              </div>
              <div className="div__email">CPF</div>
              <div className="div__email" style={{ flex: 2 }}>
                E-mail
              </div>
              <div className="div__role">Cargo</div>
              <div className="div__password">Senha</div>
              <div className="div__options">Opções</div>
            </div>
            {userListOrdered.map(
              (usuario, idx) =>
                user?.id !== usuario.id && (
                  <Card key={idx}>
                    <div
                      className="div__name"
                      style={{
                        flex: 2,
                        color: usuario.validated
                          ? 'var(--primary2)'
                          : 'var(--warn)',
                      }}
                    >
                      {usuario?.name}
                    </div>
                    <div className="div__email">{usuario.cpf}</div>
                    <div className="div__email" style={{ flex: 2 }}>
                      {usuario.email}
                    </div>
                    <div className="div__role">{usuario.role}</div>
                    <div className="div__password">
                      (
                      <a
                        href="#password"
                        onClick={() =>
                          setShowChangePasswordDialog({
                            open: true,
                            dados: usuario,
                          })
                        }
                      >
                        trocar senha
                      </a>
                      )
                    </div>
                    <div className="div__options">
                      {/* <IconButton>
                    <VisibilityIcon />
                  </IconButton> */}
                      <IconButton
                        onClick={() =>
                          setNewUserDialog({
                            ...showNewUserDialog,
                            open: true,
                            edit: true,
                            dados: usuario,
                          })
                        }
                      >
                        <EditIcon />
                      </IconButton>

                      <IconButton
                        className="btn_delete"
                        onClick={() =>
                          setShowDeleteUserDialogConf({
                            ...showDeleteUserDialogConf,
                            status: true,
                            dados: usuario,
                          })
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </Card>
                )
            )}

            {userListOrdered.length === 0 && filter.type ? (
              <div style={{ padding: '1rem' }}>Nenhum usuário encontrado</div>
            ) : userListOrdered.length > 0 ? (
              ''
            ) : (
              <div style={{ padding: '1rem' }}>Nenhum usuario cadastrado</div>
            )}
          </div>
          <br />
        </content>
      </Page>
      {showaltdados && (
        <Dialogoalterardados
          // dados={dados}
          fechar={() => setShowaltdados(false)}
        />
      )}
      {showChangePasswordDialog.open && (
        <Dialogotrocarsenha
          negar={() => setShowChangePasswordDialog({ open: false, dados: '' })}
          confirmar={({ old_password, new_password, password_confirmation }) =>
            handleChangePassword({
              old_password,
              new_password,
              password_confirmation,
            })
          }
          dados={showChangePasswordDialog.dados}
        />
      )}

      {showDeleteUserDialogConf.status && (
        <Dialogoconfirmacao
          status={showDeleteUserDialogConf.status}
          width={showDeleteUserDialogConf.width}
          delete={showDeleteUserDialogConf.delete}
          description={showDeleteUserDialogConf.description}
          fechar={() =>
            setShowDeleteUserDialogConf({
              status: false,
              width: 'sm',
              title: 'Confirmar',
              description: 'Deseja realmente suspender este projeto?',
              btn_conf: 'Sim',
              btn_cancel: 'Não',
              delete: true,
              dados: '',
            })
          }
          confirmar={async () => {
            try {
              await DELETE_USER_COMPANY(
                showDeleteUserDialogConf.dados.id,
                setShowBackdrop,
                refreshUserList
              );
              toast.success(
                `Usuário ${showDeleteUserDialogConf.dados.name} deletado com sucesso`
              );
            } catch (err) {
              toast.warn('Não foi possível deletar o usuário');
            }
          }}
        />
      )}
      {showBackdrop && <Backdrop />}
      {showNewUserDialog.open && (
        <Dialogonewuser
          fechar={() =>
            setNewUserDialog({ open: false, edit: false, dados: '' })
          }
          edit={showNewUserDialog.edit}
          dados={{
            ...showNewUserDialog.dados,
          }}
          setShowbackdrop={setShowBackdrop}
          atualizar={() => GET_USERS_COMPANY(setUsersList, setShowBackdrop)}
          editar={() => true}
        />
      )}
    </Container>
  );
}

export default Users;
