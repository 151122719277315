import React, { useState, useEffect } from 'react';

import { Container, Page, Card1, Card2, Popmenu } from './styles';

// date fns
import {
  addDays,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachYearOfInterval,
} from 'date-fns';

// icones
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PrintIcon from '@material-ui/icons/Print';

// popover
import Popover from '@material-ui/core/Popover';

// backdrop
import Backdrop from '../../../components/Backdrop';

// graficos
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Bar,
  Label,
  LineChart,
  Line,
  ResponsiveContainer,
  PieChart,
  Pie,
  LabelList,
  Sector,
} from 'recharts';

import {
  GET_PROJECTS_DELAYED,
  GET_DEALERS_DATA,
  GET_RECEIPTS_AMOUNT_BY_DATE,
  GET_REVENUE_BY_DATE,
  GET_BUDGETING_BY_DATE,
  GET_RECEIPTS_TOTAL,
  GET_COMPANY_TOTAL,
} from '../../../services/endpoints';

function Dashboard() {
  const [showbackdrop, setShowbackdrop] = useState(false);
  const [totalcompany, setTotalcompany] = useState();
  const [projectsdelayed, setProjectsdelayed] = useState({
    total_projects: 0,
    finished_projects: 0,
    delayed_projects: 0,
    delay_in_one_day: 0,
    delay_in_three_days: 0,
    delay_in_ten_days: 0,
  });
  const [selecteddelay, setSelecteddelay] = useState('delay_in_one_day');
  // popover
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClickPop = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePop = () => {
    setAnchorEl(null);
    setTimeout(() => setAuxiliar(), 100);
  };
  const openPop = anchorEl;
  const id = openPop ? 'simple-popover' : undefined;

  const [dados, setDados] = useState([
    {
      id: 1,
      title: 'QTD. EMPRESAS CADASTRADAS',
      value: '52',
      label: '',
    },
    {
      id: 2,
      title: 'Qtd. de projetos contratados',
      value: '134',
      label: '',
    },
    {
      id: 3,
      title: 'Qtd. de orçamentos',
      value: '522',
      label: '',
    },
  ]);

  const [dadosreceiptdate, setDadosreceiptdate] = useState({
    end_date: new Date().toISOString().split('T')[0],
    start_date: new Date(new Date().setDate(new Date().getDate() - 10))
      .toISOString()
      .split('T')[0],
    month: false,
    day: true,
  });

  const [dadosrevenuedate, setDadosrevenuedate] = useState({
    end_date: new Date().toISOString().split('T')[0],
    start_date: new Date(new Date().setDate(new Date().getDate() - 10))
      .toISOString()
      .split('T')[0],
    month: false,
    day: true,
  });

  const [auxiliar, setAuxiliar] = useState();
  const [getAmountByDateInterval, setGetAmountByDateInterval] = useState([]);
  const [getRevenueByDateInterval, setGetRevenueByDateInterval] = useState([]);
  const [budgetingDateInterval, setBudgetingDateInterval] = useState([]);
  const [dadosgeralrecibos, setDadosgeralrecibos] = useState({
    total_receipts: '',
    paid_receipts: '',
    open_receipts: '',
  });

  const [activeIndex, setActiveIndex] = useState(0);

  const [dadosdealers, setDadosdealers] = useState({
    filter: 'state',
    start_date: '',
    end_date: '',
  });
  const [listdealersprojects, setListdealersprojects] = useState([
    { total_projects: 1, state: 'Carregando...' },
  ]);

  const [liststatesprojects, setListstatesprojects] = useState([
    { total_projects: 1, state: 'Carregando...' },
  ]);

  const data = [
    {
      name: 'Janeiro',
      new_projects: 4000,
      finished_projects: 2400,
    },
    {
      name: 'Fevereiro',
      new_projects: 3000,
      finished_projects: 1398,
    },
    {
      name: 'Março',
      new_projects: 2000,
      finished_projects: 5800,
    },
    {
      name: 'Abril',
      new_projects: 2780,
      finished_projects: 3908,
    },
    {
      name: 'Maio',
      new_projects: 120,
      finished_projects: 1536,
    },
    {
      name: 'Junho',
      new_projects: 4541,
      finished_projects: 2554,
    },
    {
      name: 'Julho',
      new_projects: 854,
      finished_projects: 1222,
    },
    {
      name: 'Agosto',
      new_projects: 4455,
      finished_projects: 12,
    },
    {
      name: 'Setembro',
      new_projects: 633,
      finished_projects: 122,
    },
    {
      name: 'Outubro',
      new_projects: 3345,
      finished_projects: 2500,
    },
    {
      name: 'Novembro',
      new_projects: 2180,
      finished_projects: 4108,
    },
    {
      name: 'Dezembro',
      new_projects: 2780,
      finished_projects: 1908,
    },
  ];

  const selectProjectDelayed = (event) => {
    setSelecteddelay(event.target.name);
    handleClosePop();
  };

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const getintervalformap = (metod, data) => {
    switch (metod) {
      case 'day':
        return eachDayOfInterval({
          start: addDays(new Date(data?.start_date), 1),
          end: addDays(new Date(data?.end_date), 1),
        });
        break;
      case 'month':
        return eachMonthOfInterval({
          start: addDays(new Date(data?.start_date), 1),
          end: addDays(new Date(data?.end_date), 1),
        });
        break;
      case 'year':
        return eachYearOfInterval({
          start: addDays(new Date(data?.start_date), 1),
          end: addDays(new Date(data?.end_date), 1),
        });
        break;
      default:
        return eachDayOfInterval({
          start: addDays(new Date(data?.start_date), 1),
          end: addDays(new Date(data?.end_date), 1),
        });
        break;
    }
  };

  useEffect(() => {
    GET_PROJECTS_DELAYED(setProjectsdelayed, setShowbackdrop);
    GET_DEALERS_DATA(setListstatesprojects, dadosdealers, setShowbackdrop);
    GET_DEALERS_DATA(
      setListdealersprojects,
      { ...dadosdealers, filter: 'name' },
      setShowbackdrop,
    );
    GET_RECEIPTS_AMOUNT_BY_DATE(
      setGetAmountByDateInterval,
      dadosreceiptdate,
      setShowbackdrop,
    );
    GET_REVENUE_BY_DATE(
      setGetRevenueByDateInterval,
      dadosrevenuedate,
      setShowbackdrop,
    );

    GET_BUDGETING_BY_DATE(
      setBudgetingDateInterval,
      dadosreceiptdate,
      setShowbackdrop,
    );
    GET_RECEIPTS_TOTAL(setDadosgeralrecibos, setShowbackdrop);
    GET_COMPANY_TOTAL(setTotalcompany, setShowbackdrop);
  }, []);

  return (
    <Container>
      <Page>
        <header>
          <h1>DashBoard</h1>
          <span>
            Acompanhe de forma visual todas as principais métricas do sistema.
          </span>
          {/* <button
            
          >
            <PrintIcon /> Imprimir
          </button> */}
        </header>
        <content id="field_print">
          <div className="div__oneRow" style={{ flexWrap: 'wrap' }}>
            <Card1
              idx={0}
              style={{
                background: `var(--colorcard1)`,
              }}
            >
              <h4>Total de Projetos</h4>
              <p>{projectsdelayed.total_projects || '...'}</p>
            </Card1>
            <Card1
              idx={1}
              style={{
                background: `var(--colorcard2)`,
              }}
            >
              <h4>Projetos Finalizados</h4>
              <p>{projectsdelayed.finished_projects || '...'}</p>
            </Card1>
            <Card1
              idx={2}
              style={{
                background: `var(--colorcard3)`,
              }}
            >
              <h4>Projetos em Atraso</h4>
              <p>{projectsdelayed.delayed_projects || '...'}</p>
            </Card1>
            <Card1
              idx={3}
              style={{
                background: `var(--colorcard4)`,
              }}
            >
              <div className="top_button">
                <button
                  aria-describedby={id}
                  onClick={handleClickPop}
                  name="selecteddelay"
                >
                  <ArrowDropDownIcon />
                </button>
              </div>
              <h4>
                Projetos a{' '}
                {selecteddelay === 'delay_in_one_day'
                  ? 'um dia'
                  : selecteddelay === 'delay_in_three_days'
                  ? 'três dias'
                  : selecteddelay === 'delay_in_ten_days'
                  ? 'dez dias'
                  : '-'}{' '}
                para atrasar
              </h4>
              <p>
                {selecteddelay === 'delay_in_one_day'
                  ? projectsdelayed.delay_in_one_day
                  : selecteddelay === 'delay_in_three_days'
                  ? projectsdelayed.delay_in_three_days
                  : selecteddelay === 'delay_in_ten_days'
                  ? projectsdelayed.delay_in_ten_days
                  : '...'}
              </p>
            </Card1>
            <Card1
              idx={0}
              style={{
                background: `var(--colorcard1)`,
              }}
            >
              <h4>QTD. EMPRESAS CADASTRADAS</h4>
              <p>{totalcompany?.total_companies || '...'}</p>
            </Card1>

            <Card1
              idx={2}
              style={{
                background: `var(--colorcard3)`,
              }}
            >
              <h4>Projetos pagos</h4>
              <p>{dadosgeralrecibos.paid_receipts || '...'}</p>
            </Card1>

            <Card1
              idx={3}
              style={{
                background: `var(--colorcard4)`,
              }}
            >
              <h4>Projetos não pagos</h4>
              <p>{dadosgeralrecibos.open_receipts || '...'}</p>
            </Card1>
          </div>
          <div
            className="div__oneRow"
            style={{ margin: 'auto', maxWidth: '1100px', display: 'flex' }}
          >
            <div className="div__oneCol" style={{ flex: 1 }}>
              <div
                className="div__oneRow"
                style={{
                  flex: 1,
                  flexWrap: 'wrap',
                  // maxWidth: '250px',
                  margin: 'auto',
                }}
              >
                {/* <Card2
                  graphPercent={61}
                  style={{
                    background: `rgba(var(--black_rgb), 0.04 )`,
                  }}
                >
                  <div className="div__conteudo">
                    <div class="graph">
                      <svg>
                        <circle cx="50" cy="50" r="40" />
                      </svg>
                      <svg class="cor">
                        <circle cx="50" cy="50" r="40" />
                      </svg>
                      <div className="div__in_graph">
                        <span class="counter">61</span>
                        <span class="total">/100%</span>
                      </div>
                    </div>
                    <div className="div__legend">
                      <ul>
                        <li>Projetos em aberto</li>
                        <li>Total de projetos</li>
                      </ul>
                    </div>
                  </div>
                </Card2> */}

                <Card2>
                  <h4>Projetos por estado</h4>
                  {liststatesprojects.length > 0 && (
                    <ResponsiveContainer width="100%" aspect={4.0 / 3.0}>
                      <PieChart>
                        <Pie
                          activeIndex={liststatesprojects.map((_, idx) => idx)}
                          activeShape={renderActiveShapeState}
                          data={liststatesprojects.filter(
                            (pj) => pj.total_projects > 0,
                          )}
                          cx="50%"
                          cy="50%"
                          paddingAngle={2}
                          innerRadius={50}
                          outerRadius={80}
                          fill="var(--tertiary)"
                          dataKey="total_projects"
                          onMouseEnter={onPieEnter}
                          labelLine={false}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  )}
                </Card2>

                <Card2>
                  <h4>Projetos por concessionária</h4>
                  {listdealersprojects.length > 0 && (
                    <ResponsiveContainer width="100%">
                      <PieChart>
                        <Pie
                          activeIndex={listdealersprojects.map((_, idx) => idx)}
                          activeShape={renderActiveShapeState}
                          data={listdealersprojects.filter(
                            (pj) => pj.total_projects > 0,
                          )}
                          cx="50%"
                          cy="50%"
                          paddingAngle={2}
                          innerRadius={50}
                          outerRadius={80}
                          fill="var(--tertiary)"
                          dataKey="total_projects"
                          onMouseEnter={onPieEnter}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  )}
                </Card2>

                {/* <Card2>
                  <h4>Inscritos por mês</h4>
                  <ResponsiveContainer width="100%" aspect={4.0 / 3.0}>
                    <PieChart>
                      <Pie
                        data={data.slice(0, 6)}
                        dataKey="finished_projects"
                        nameKey="name"
                        name="Projetos Finalizados"
                        cx="50%"
                        cy="50%"
                        innerRadius={20}
                        outerRadius={60}
                        fill="var(--tertiary)"
                        label={(a) => a.name}
                      >
                        <LabelList
                          dataKey="finished_projects"
                          position="bottom"
                          style={{
                            fontSize: '12px',
                            fontWeight: '100',
                          }}
                        />
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Card2> */}
              </div>
            </div>
            <div
              className="div__oneCol"
              style={{ flex: 2, maxWidth: '1100px', flexDirection: 'column' }}
            >
              <div
                className="div__graph_bar"
                style={{
                  width: '95%',
                  minWidth: window.innerWidth > 675 ? '350px' : '100px',
                }}
              >
                <div className="top_button">
                  <button
                    aria-describedby={id}
                    onClick={handleClickPop}
                    name="dadosreceiptdate"
                  >
                    <ArrowDropDownIcon />
                  </button>
                </div>

                <h3>
                  Projetos e orçamentos por{' '}
                  {dadosreceiptdate.day
                    ? 'dia'
                    : dadosreceiptdate.month
                    ? 'mês'
                    : 'ano'}
                </h3>
                <ResponsiveContainer
                  width="100%"
                  minHeight="234px"
                  // aspect={4.0 / 2.0}
                >
                  {dadosreceiptdate?.start_date &&
                    dadosreceiptdate?.end_date && (
                      <LineChart
                        // width={1000}
                        // height={250}
                        data={getintervalformap(
                          dadosreceiptdate.day === true
                            ? 'day'
                            : dadosreceiptdate.month === true
                            ? 'month'
                            : 'year',
                          dadosreceiptdate,
                        ).map((day, idx) => {
                          let aux =
                            '' + new Date(day).toISOString().split('T')[0];
                          return {
                            date: day.toLocaleDateString(
                              'pt-BR',
                              dadosreceiptdate.day
                                ? {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: '2-digit',
                                  }
                                : dadosreceiptdate.month
                                ? {
                                    month: '2-digit',
                                    year: 'numeric',
                                  }
                                : {
                                    year: 'numeric',
                                  },
                            ),
                            amount:
                              getAmountByDateInterval?.find(
                                (a) =>
                                  a.date ===
                                  (a.date.length === 10
                                    ? aux
                                    : a.date.length === 7
                                    ? aux.slice(0, 7)
                                    : aux.slice(0, 4)),
                              )?.amount || 0,
                            amount_budget:
                              budgetingDateInterval?.find(
                                (a) =>
                                  a.date ===
                                  (a.date.length === 10
                                    ? aux
                                    : a.date.length === 7
                                    ? aux.slice(0, 7)
                                    : aux.slice(0, 4)),
                              )?.amount || 0,
                          };
                        })}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="amount"
                          name="Projetos"
                          stroke="var(--primary2)"
                        />
                        <LineChart
                          type="monotone"
                          dataKey="amount_budget"
                          name="Orçamentos realizados"
                          stroke="var(--tertiary)"
                        />
                      </LineChart>
                    )}
                </ResponsiveContainer>
              </div>
              <div
                className="div__graph_bar"
                style={{
                  width: '95%',
                  minWidth: window.innerWidth > 675 ? '350px' : '100px',
                }}
              >
                <div className="top_button">
                  <button
                    aria-describedby={id}
                    onClick={handleClickPop}
                    name="dadosrevenuedate"
                  >
                    <ArrowDropDownIcon />
                  </button>
                </div>
                <h3>
                  Receita por{' '}
                  {dadosrevenuedate.day
                    ? 'dia'
                    : dadosrevenuedate.month
                    ? 'mês'
                    : 'ano'}{' '}
                  (R$)
                </h3>
                <ResponsiveContainer
                  width="100%"
                  height="100%"
                  minHeight="224px"
                  // aspect={4.0 / 1.75}
                >
                  {dadosrevenuedate?.start_date &&
                    dadosrevenuedate?.end_date && (
                      <BarChart
                        // width={490}
                        // height={250}
                        data={getintervalformap(
                          dadosrevenuedate.day === true
                            ? 'day'
                            : dadosrevenuedate.month === true
                            ? 'month'
                            : 'year',
                          dadosrevenuedate,
                        ).map((day, idx) => {
                          let aux =
                            '' + new Date(day).toISOString().split('T')[0];
                          return {
                            date: day.toLocaleDateString(
                              'pt-BR',
                              dadosrevenuedate.day
                                ? {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: '2-digit',
                                  }
                                : dadosrevenuedate.month
                                ? {
                                    month: '2-digit',
                                    year: 'numeric',
                                  }
                                : {
                                    year: 'numeric',
                                  },
                            ),
                            revenue:
                              Number(
                                getRevenueByDateInterval?.find(
                                  (a) =>
                                    a.date ===
                                    (a.date.length === 10
                                      ? aux
                                      : a.date.length === 7
                                      ? aux.slice(0, 7)
                                      : aux.slice(0, 4)),
                                )?.revenue,
                              ) || 0,
                          };
                        })}
                        style={{
                          padding: '0rem 0rem',
                        }}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar
                          dataKey="revenue"
                          fill="var(--primary2)"
                          name="Receita"
                        />

                        {/* <Bar
                        dataKey="finished_projects"
                        fill="var(--tertiary)"
                        name="Projetos finalizados"
                      /> */}
                      </BarChart>
                    )}
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <div className="div__oneRow"></div>
        </content>
        <br />
      </Page>

      {/* popovers  */}
      {/* projetos atrasados */}
      <Popover
        id={id}
        open={openPop?.name === 'selecteddelay'}
        anchorEl={anchorEl}
        onClose={handleClosePop}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Popmenu>
          <button
            className={selecteddelay === 'delay_in_one_day' ? 'destaque' : ''}
            name="delay_in_one_day"
            onClick={selectProjectDelayed}
          >
            um dia para atrasar
          </button>
          <button
            className={
              selecteddelay === 'delay_in_three_days' ? 'destaque' : ''
            }
            name="delay_in_three_days"
            onClick={selectProjectDelayed}
          >
            três dias para atrasar
          </button>
          <button
            className={selecteddelay === 'delay_in_ten_days' ? 'destaque' : ''}
            name="delay_in_ten_days"
            onClick={selectProjectDelayed}
          >
            dez dias para atrasar
          </button>
        </Popmenu>
      </Popover>
      {/* projetos e orçamentos por periodo de tempo  */}
      <Popover
        id={id}
        open={openPop?.name === 'dadosreceiptdate'}
        anchorEl={anchorEl}
        onClose={handleClosePop}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Popmenu>
          <form
            onSubmit={(event) => {
              event.preventDefault();

              GET_RECEIPTS_AMOUNT_BY_DATE(
                setGetAmountByDateInterval,
                {
                  end_date: auxiliar?.end_date
                    ? auxiliar.end_date
                    : dadosreceiptdate.end_date,
                  start_date: auxiliar?.start_date
                    ? auxiliar.start_date
                    : dadosreceiptdate.start_date,
                  month: auxiliar?.month
                    ? auxiliar.month
                    : auxiliar?.change
                    ? false
                    : dadosreceiptdate.month,
                  day: auxiliar?.day
                    ? auxiliar.day
                    : auxiliar?.change
                    ? false
                    : auxiliar.changeMonth
                    ? false
                    : dadosreceiptdate.day,
                },
                setShowbackdrop,
              );
              GET_BUDGETING_BY_DATE(
                setBudgetingDateInterval,
                {
                  end_date: auxiliar?.end_date
                    ? auxiliar.end_date
                    : dadosreceiptdate.end_date,
                  start_date: auxiliar?.start_date
                    ? auxiliar.start_date
                    : dadosreceiptdate.start_date,
                  month: auxiliar?.month
                    ? auxiliar.month
                    : auxiliar?.change
                    ? false
                    : dadosreceiptdate.month,
                  day: auxiliar?.day
                    ? auxiliar.day
                    : auxiliar?.change
                    ? false
                    : auxiliar.changeMonth
                    ? false
                    : dadosreceiptdate.day,
                },
                setShowbackdrop,
              );
              setDadosreceiptdate({
                end_date: auxiliar?.end_date
                  ? auxiliar.end_date
                  : dadosreceiptdate.end_date,
                start_date: auxiliar?.start_date
                  ? auxiliar.start_date
                  : dadosreceiptdate.start_date,
                month: auxiliar?.month
                  ? auxiliar.month
                  : auxiliar?.change
                  ? false
                  : dadosreceiptdate.month,
                day: auxiliar?.day
                  ? auxiliar.day
                  : auxiliar?.change
                  ? false
                  : auxiliar.changeMonth
                  ? false
                  : dadosreceiptdate.day,
              });
              handleClosePop();
            }}
          >
            <input
              type="date"
              placeholder="Data inicial"
              value={
                auxiliar?.start_date
                  ? auxiliar.start_date
                  : dadosreceiptdate.start_date
              }
              max={
                auxiliar?.end_date
                  ? auxiliar.end_date
                  : dadosreceiptdate.end_date
              }
              onChange={(event) => {
                setAuxiliar({
                  ...auxiliar,
                  start_date: new Date(event.target.value)
                    .toISOString()
                    .split('T')[0],
                });
              }}
            />
            <input
              type="date"
              placeholder="Data final"
              value={
                auxiliar?.end_date
                  ? auxiliar.end_date
                  : dadosreceiptdate.end_date
              }
              min={
                auxiliar?.start_date
                  ? auxiliar.start_date
                  : dadosreceiptdate.start_date
              }
              onChange={(event) => {
                setAuxiliar({
                  ...auxiliar,
                  end_date: new Date(event.target.value)
                    .toISOString()
                    .split('T')[0],
                });
              }}
            />
            <select
              value={
                !auxiliar?.month && !auxiliar?.day && !auxiliar?.change
                  ? dadosreceiptdate.month && dadosreceiptdate.day
                    ? 'day'
                    : dadosreceiptdate.day
                    ? 'day'
                    : dadosreceiptdate.month
                    ? 'month'
                    : 'year'
                  : auxiliar?.month && auxiliar?.day
                  ? 'day'
                  : auxiliar?.day
                  ? 'day'
                  : auxiliar?.month
                  ? 'month'
                  : 'year'
              }
              onChange={(event) => {
                switch (event.target.value) {
                  case 'day':
                    setAuxiliar({
                      ...auxiliar,
                      day: true,
                      month: false,
                    });
                    break;
                  case 'month':
                    setAuxiliar({
                      ...auxiliar,
                      day: false,
                      month: true,
                      changeMonth: true,
                    });
                    break;
                  case 'year':
                    setAuxiliar({
                      ...auxiliar,
                      day: false,
                      month: false,
                      change: true,
                    });
                    break;
                  default:
                    setAuxiliar({
                      ...auxiliar,
                      day: true,
                      month: false,
                    });
                    break;
                }
              }}
            >
              <option value="" disabled>
                Filtrar por
              </option>
              <option value="day">Dia</option>
              <option value="month">Mês</option>
              <option value="year">Ano</option>
            </select>
            <button
              className={'destaque'}
              name="delay_in_one_day"
              // onClick={selectProjectDelayed}
            >
              Consultar
            </button>
          </form>
        </Popmenu>
      </Popover>
      {/* receita por periodo de  tempo */}
      <Popover
        id={id}
        open={openPop?.name === 'dadosrevenuedate'}
        anchorEl={anchorEl}
        onClose={handleClosePop}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Popmenu>
          <form
            onSubmit={(event) => {
              event.preventDefault();

              GET_REVENUE_BY_DATE(
                setGetRevenueByDateInterval,
                {
                  end_date: auxiliar?.end_date
                    ? auxiliar.end_date
                    : dadosrevenuedate.end_date,
                  start_date: auxiliar?.start_date
                    ? auxiliar.start_date
                    : dadosrevenuedate.start_date,
                  month: auxiliar?.month
                    ? auxiliar.month
                    : auxiliar?.change
                    ? false
                    : dadosrevenuedate.month,
                  day: auxiliar?.day
                    ? auxiliar.day
                    : auxiliar?.change
                    ? false
                    : auxiliar.changeMonth
                    ? false
                    : dadosrevenuedate.day,
                },
                setShowbackdrop,
              );

              setDadosrevenuedate({
                end_date: auxiliar?.end_date
                  ? auxiliar.end_date
                  : dadosrevenuedate.end_date,
                start_date: auxiliar?.start_date
                  ? auxiliar.start_date
                  : dadosrevenuedate.start_date,
                month: auxiliar?.month
                  ? auxiliar.month
                  : auxiliar?.change
                  ? false
                  : dadosrevenuedate.month,
                day: auxiliar?.day
                  ? auxiliar.day
                  : auxiliar?.change
                  ? false
                  : auxiliar.changeMonth
                  ? false
                  : dadosrevenuedate.day,
              });
              handleClosePop();
            }}
          >
            <input
              type="date"
              placeholder="Data inicial"
              value={
                auxiliar?.start_date
                  ? auxiliar.start_date
                  : dadosrevenuedate.start_date
              }
              max={
                auxiliar?.end_date
                  ? auxiliar.end_date
                  : dadosrevenuedate.end_date
              }
              onChange={(event) => {
                setAuxiliar({
                  ...auxiliar,
                  start_date: new Date(event.target.value)
                    .toISOString()
                    .split('T')[0],
                });
              }}
            />
            <input
              type="date"
              placeholder="Data final"
              value={
                auxiliar?.end_date
                  ? auxiliar.end_date
                  : dadosrevenuedate.end_date
              }
              min={
                auxiliar?.start_date
                  ? auxiliar.start_date
                  : dadosrevenuedate.start_date
              }
              onChange={(event) => {
                setAuxiliar({
                  ...auxiliar,
                  end_date: new Date(event.target.value)
                    .toISOString()
                    .split('T')[0],
                });
              }}
            />
            <select
              value={
                !auxiliar?.month && !auxiliar?.day && !auxiliar?.change
                  ? dadosrevenuedate.month && dadosrevenuedate.day
                    ? 'day'
                    : dadosrevenuedate.day
                    ? 'day'
                    : dadosrevenuedate.month
                    ? 'month'
                    : 'year'
                  : auxiliar?.month && auxiliar?.day
                  ? 'day'
                  : auxiliar?.day
                  ? 'day'
                  : auxiliar?.month
                  ? 'month'
                  : 'year'
              }
              onChange={(event) => {
                switch (event.target.value) {
                  case 'day':
                    setAuxiliar({
                      ...auxiliar,
                      day: true,
                      month: false,
                    });
                    break;
                  case 'month':
                    setAuxiliar({
                      ...auxiliar,
                      day: false,
                      month: true,
                      changeMonth: true,
                    });
                    break;
                  case 'year':
                    setAuxiliar({
                      ...auxiliar,
                      day: false,
                      month: false,
                      change: true,
                    });
                    break;
                  default:
                    setAuxiliar({
                      ...auxiliar,
                      day: true,
                      month: false,
                    });
                    break;
                }
              }}
            >
              <option value="" disabled>
                Filtrar por
              </option>
              <option value="day">Dia</option>
              <option value="month">Mês</option>
              <option value="year">Ano</option>
            </select>
            <button
              className={'destaque'}
              name="delay_in_one_day"
              // onClick={selectProjectDelayed}
            >
              Consultar
            </button>
          </form>
        </Popmenu>
      </Popover>
      {/* fim de popovers  */}
      {showbackdrop && <Backdrop />}
    </Container>
  );
}

const breakDataText = (payload, idx) => {
  if (payload.length < 9) {
    return payload;
  } else {
    if (idx === 0) {
      return payload.slice(0, 7);
    } else if (idx === 1) {
      return payload.slice(7, 13) + '.';
    }
  }
};
const renderActiveShapeState = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 10) * cos;
  const my = cy + (outerRadius + 10) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  // para numero central
  const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        Projetos
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
        style={{
          fontSize: '0.75rem',
        }}
      >
        {breakDataText(payload.state ? payload.state : payload.name, 0)}
      </text>

      {(payload.state ? payload.state : payload.name).length > 7 && (
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={15}
          textAnchor={textAnchor}
          fill="#333"
          style={{
            fontSize: '0.75rem',
          }}
        >
          {breakDataText(payload.state ? payload.state : payload.name, 1)}
        </text>
      )}

      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={(payload.state ? payload.state : payload.name).length > 7 ? 30 : 13}
        textAnchor={textAnchor}
        fill="#999"
        style={{
          fontSize: '0.6rem',
        }}
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>

      <text
        x={x}
        y={y}
        textAnchor={x > cx ? 'start' : 'end'}
        fill="white"
        dominantBaseline="central"
        style={{
          textAlign: 'center',
        }}
      >
        {value}
        {/* {addZeroes(value, 3)} */}
      </text>
    </g>
  );
};

function addZeroes(num, len) {
  var numberWithZeroes = String(num);
  var counter = numberWithZeroes.length;

  while (counter < len) {
    numberWithZeroes = '0' + numberWithZeroes;

    counter++;
  }

  return numberWithZeroes;
}

export default Dashboard;
