import React, { useState } from 'react';

// Estilização
import { Card } from './styles';

// dica
import Tooltip from '@material-ui/core/Tooltip';

// icones
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import CloseIcon from '@material-ui/icons/Close';
import UndoIcon from '@material-ui/icons/Undo';

// fucoes
// import Dialogoverprojeto from '../Dialogoverprojeto';

// confirmacao
import Dialogoconfirmacao from '../Dialogoconfirmacao';
import Dialogoverprojeto from '../Dialogoverprojeto';

// chatbox
import Chatboxconfirm from '../Chatboxconfirm';
import { PATCH_PROJECTS_REFUSE } from '../../services/endpoints';

function CardNovosProjetos(props) {
  const [showconf, setShowconf] = useState({
    status: false,
    width: 'sm',
    title: 'Recusar',
    description: 'Deseja realmente recusar este projeto?',
    btn_conf: 'Sim',
    btn_cancel: 'Não',
    delete: true,
    dados: '',
  });

  const [solicitarmud, setSolicitarmud] = useState({
    status: false,
    dados: '',
  });

  const [viewproject, setViewproject] = useState({
    status: false,
    dados: '',
  });

  return (
    <Card
      key={props.idx}
      refused={props.proj.refused}
      abaSelected={props.abaSelected}
    >
      <div className="div__name">
        <h4>
          <AssignmentOutlinedIcon /> {props.proj?.client_name}
        </h4>
        {props?.proj?.company?.social_reason && (
          <h4>
            <BusinessIcon /> {props.proj?.company?.social_reason}
          </h4>
        )}
        {props.proj?.client_cpf && <span>CPF: {props.proj.client_cpf}</span>}
        {props.proj?.client_cnpj && <span>CNPJ: {props.proj.client_cnpj}</span>}
        <span>
          {props.proj.dealer.name} - {props.proj.dealer.state}
        </span>
        <h4>{props.proj.total_project_power} kWp</h4>
        <span>Solicitado em:</span>
        <p>{new Date(props.proj.created_at).toLocaleDateString('pt-BR')}</p>
        <div className="div__options">
          <Tooltip
            placement="bottom-start"
            title="Visualizar informações de projeto"
          >
            <button
              onClick={() =>
                setViewproject({ status: true, dados: props.proj })
              }
            >
              <VisibilityOutlinedIcon />
            </button>
          </Tooltip>

          {!props.proj.refused && (
            <Tooltip
              placement="bottom-start"
              title="Confirmar Recepção do projeto"
            >
              <button
                onClick={() =>
                  setSolicitarmud({ status: true, dados: props.proj })
                }
              >
                <PlaylistAddCheckIcon />
              </button>
            </Tooltip>
          )}

          <Tooltip
            placement="bottom-start"
            title={
              !props.proj.refused ? 'Recusar projeto' : 'Reavaliar projeto'
            }
          >
            <button
              className={!props.proj.refused && 'button_delete'}
              onClick={() =>
                setShowconf((value) => ({
                  ...value,
                  status: true,
                  description: !props.proj.refused
                    ? 'Deseja realmente recusar este projeto?'
                    : 'Deseja realmente reavaliar este projeto? O projeto será movido para Novos Projetos.',
                  delete: !props.proj.refused,
                  dados: props.proj,
                }))
              }
            >
              {!props.proj.refused ? <CloseIcon /> : <UndoIcon />}
            </button>
          </Tooltip>
        </div>
      </div>

      {showconf.status && (
        <Dialogoconfirmacao
          status={showconf.status}
          width={showconf.width}
          delete={showconf.delete}
          description={showconf.description}
          fechar={() =>
            setShowconf((value) => ({ ...value, status: false, dados: '' }))
          }
          confirmar={() => {
            PATCH_PROJECTS_REFUSE(showconf.dados, props.atualizar);
          }}
        />
      )}

      {viewproject.status && (
        <Dialogoverprojeto
          dados={{ ...viewproject.dados }}
          newproject={true}
          dados_edit={null}
          fechar={() => setViewproject({ status: false, dados: '' })}
          atualizar={props.atualizar}
        />
      )}

      {solicitarmud.status && (
        <Chatboxconfirm
          fechar={() => setSolicitarmud({ status: false, dados: '' })}
          dados={solicitarmud.dados}
        />
      )}
    </Card>
  );
}

export default CardNovosProjetos;
