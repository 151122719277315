import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { Container, FielButtons } from './styles';
import api from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';

export default function Alterardados({ handleClose, isOpen }) {
  const { user } = useAuth();

  const [userFake, setUserFake] = useState({ ...user });

  const handleChangePerson = (e) => {
    setUserFake((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCloseModal = () => {
    setTimeout(() => handleClose(), 100);
  };

  const onSubmitUpdateData = () => {
    const dadosReq = {
      ...userFake,
    };

    api
      .put('/users/profile', dadosReq)
      .then((res) => {
        // getDadosUsuario();
        handleCloseModal();
      })
      .catch((error) => {
        return toast.warn(error.response.data.error);
      });
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleCloseModal}
        maxWidth="md"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 999999,
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Container>
              <header>
                <h1>Alterar dados</h1>
                <span>
                  Preencha corretamente os campos abaixo para alterar suas
                  informações.
                </span>
                <content>
                  <h4>Dados pessoais</h4>
                  <div className="div__oneRow">
                    <input
                      type="text"
                      name="name"
                      value={userFake?.name}
                      placeholder="Nome completo"
                      style={{
                        flex: 1,
                      }}
                      onChange={handleChangePerson}
                      required
                    />
                    <input
                      type="text"
                      name="cpf"
                      value={userFake?.cpf}
                      placeholder="CPF"
                      style={{
                        flex: 1,
                      }}
                      onChange={handleChangePerson}
                      required
                    />
                  </div>

                  <div className="div__oneRow">
                    <input
                      type="text"
                      name="email"
                      value={userFake?.email}
                      placeholder="E-mail"
                      style={{
                        flex: 1,
                      }}
                      onChange={handleChangePerson}
                      required
                    />
                    <input
                      type="text"
                      name="role"
                      disabled
                      value={userFake?.role}
                      placeholder="Cargo"
                      style={{
                        flex: 1,
                      }}
                      onChange={handleChangePerson}
                      required
                    />
                  </div>
                </content>
              </header>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            <button
              onClick={handleCloseModal}
              className="btn__retornar"
              color="primary"
            >
              Cancelar
            </button>
            <button
              onClick={() => onSubmitUpdateData()}
              className="btn__register"
            >
              Aplicar alterações
            </button>
          </FielButtons>
        </DialogActions>
      </Dialog>
    </div>
  );
}
