import { useState } from 'react';
import { toast } from 'react-toastify';
import Dialogotrocarsenha from '../../../components/Dialogotrocarsenha';
import Dialogoalterardados from '../../../components/Dialogoalterardados';

import { Container, Page } from './styles';
import api from '../../../services/api';
import { useAuth } from '../../../contexts/AuthContext';
import SimpleBackdrop from '../../../components/Backdrop';

function Settings() {
  const [showBackdrop, setShowBackdrop] = useState(false);

  const { user, refreshUser } = useAuth();

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showChangeDataModal, setShowChangeDataModal] = useState(false);

  const updatePassword = async ({
    old_password,
    new_password,
    password_confirmation,
  }) => {
    setShowBackdrop(true);
    await api
      .put(`/users/profile`, {
        old_password,
        new_password,
        password_confirmation,
      })
      .then((res) => {
        toast.success('Senha alterada com sucesso.');
        setShowChangePasswordModal(false);
        setShowBackdrop(false);
      })
      .catch((error) => {
        toast.error('Erro ao alterar senha.');
        console.log(JSON.stringify(error));
      });
  };

  return (
    <Container>
      {true ? (
        <Page>
          <header>
            <h1>Configurações</h1>
            <span>
              Edite seus dados pessoais e as configurações de acesso de sua
              conta.
            </span>
          </header>
          <content>
            <div className="div__conteudo">
              {/* {!completedData && (
                <Alert severity="warning" className="div__alertas">
                  <h3>Confirmar cadastro</h3>
                  Preencha adequadamente as informações abaixo para completar
                  seu cadastro e solicitar seu primeiro projeto.
                </Alert>
              )} */}

              <div className="div__row">
                <strong>E-mail: </strong>
                {user?.email}
              </div>
              <div className="div__row">
                <strong>Senha: </strong>
                ******{' '}
                <small>
                  (
                  <a
                    href="#password"
                    onClick={() => setShowChangePasswordModal(true)}
                  >
                    trocar senha
                  </a>
                  )
                </small>
              </div>

              <h4>Dados pessoais</h4>
              <div className="div__oneRow">
                <p>
                  <label>Nome: </label> {user?.name}
                </p>
                <p>
                  <label>CPF: </label> {user?.cpf}
                </p>
              </div>

              <h4>Minhas atribuições na empresa</h4>
              <div className="div__oneRow">
                <p>
                  <label>Cargo: </label> {user?.role || ' - '}
                </p>
              </div>

              <br />
              {/* botoes */}
              <button
                type="submit"
                className="btn__finish"
                onClick={() => setShowChangeDataModal(true)}
              >
                Editar
              </button>
            </div>
          </content>
        </Page>
      ) : (
        <h1></h1>
      )}
      {!!showChangePasswordModal && (
        <Dialogotrocarsenha
          negar={() => setShowChangePasswordModal(false)}
          confirmar={({ old_password, new_password, password_confirmation }) =>
            updatePassword({
              old_password,
              new_password,
              password_confirmation,
            })
          }
        />
      )}{' '}
      {showChangeDataModal && (
        <Dialogoalterardados
          handleClose={() => {
            setShowChangeDataModal(false);
            refreshUser();
          }}
          isOpen={showChangeDataModal}
        />
      )}
      {showBackdrop && <SimpleBackdrop />}
    </Container>
  );
}

export default Settings;
