import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';

import { Container, FielButtons } from './styles';
import api from '../../services/api';
import Backdrop from '../../components/Backdrop';
import { listetapas } from '../../config';

function Chatboxconfirm(props) {
  const [supervisorProcesso, setSupervisorProcesso] = useState([]);
  let history = useHistory();
  const faseProcesso = listetapas;
  const [showbackdrop, setShowbackdrop] = useState(false);
  const [enviado, setEnviado] = useState(false);
  const [dados, setDados] = useState({
    supervisor_process: '',
    date_conclusion: '',
    name_process: '',
    stage_process: '',
    new_stage_process: '',
    date_limit: '',
    note: '',
  });

  const getFuncionarios = async () => {
    await api
      .get(`/users/admin/list-users`, {
        params: {
          roles: JSON.stringify(['GERENTE', 'ADMINISTRADOR']),
        },
      })
      .then((res) => {
        setSupervisorProcesso(res.data);
      })
      .catch((error) => {
        toast.error(error.response.data.messages);
        setShowbackdrop(false);
      });
  };

  // console.log('confirmar: ', props.dados);
  function handleChange({ target }) {
    setDados({ ...dados, [target.name]: target.value });
  }

  const aceitarProjeto = async (event) => {
    event.preventDefault();
    setShowbackdrop(true);

    // atualizar deadline
    await api
      .patch(`/projects/deadline/${props.dados.id}`, {
        deadline: new Date(dados.date_conclusion + ' 23:59').toISOString(),
      })
      .then((res) => {
        enviarResponsavel();
      })
      .catch((error) => {
        toast.error(error.response.data.messages);
        setShowbackdrop(false);
      });
  };

  const enviarResponsavel = async () => {
    let data_req = {
      project_id: props.dados.id,
      user_id: JSON.parse(dados.supervisor_process).id,
    };

    await api
      .post(`/responsibles`, data_req)
      .then((res) => {
        enviarEstagio();
      })
      .catch((error) => {
        toast.error(error.response.data.messages);
        setShowbackdrop(false);
      });
  };

  const enviarEstagio = async () => {
    await api
      .post(`/stages`, {
        project_id: props.dados.id,
        position: 1,
        title: dados.stage_process,
        deadline: new Date(dados.date_limit + ' 23:59').toISOString(),
        description: dados.note,
      })
      .then((res) => {
        enviarStatus(res.data.id);
      })
      .catch((error) => {
        toast.error(error.response.data.messages);
        setShowbackdrop(false);
      });
  };

  const enviarStatus = async (etapa_id) => {
    await api
      .post(`/status`, {
        stage_id: etapa_id,
        description: dados.note,
      })
      .then((res) => {
        toast.success('Projeto aceito com sucesso!');
        history.push(`/integradores/projetos/${props.dados.company_id}`);
      })
      .catch((error) => {
        toast.error(error.response.data.messages);
        setShowbackdrop(false);
      });
  };

  useEffect(() => {
    getFuncionarios();
  }, []);

  return (
    <Container>
      <h3>Confirmação do Projeto</h3>
      {!enviado ? (
        <form onSubmit={aceitarProjeto}>
          <div className="div__corpo">
            <label htmlFor="name_project">Projeto</label>
            <span>
              {' '}
              <AssignmentOutlinedIcon /> {props.dados.client_name}
            </span>
            <label htmlFor="supervisor_process">Supervisor do Projeto*</label>
            <select
              id="supervisor_process"
              name="supervisor_process"
              value={dados.supervisor_process}
              onChange={handleChange}
              required
            >
              <option value="" disabled selected>
                Selecione o supervisor
              </option>
              {supervisorProcesso?.map((item, idx) => (
                <option key={idx} value={JSON.stringify(item)}>
                  {item?.name}
                </option>
              ))}
            </select>
            <label htmlFor="date_conclusion">
              Previsão de conclusão do projeto*
            </label>
            <input
              id="date_conclusion"
              name="date_conclusion"
              type="date"
              value={dados.date_conclusion}
              onChange={handleChange}
              required
            />
            <div
              className="div__separator"
              style={{
                borderTop: '1px dashed rgba(0,0,0,0.5)',
                marginTop: '0.5rem',
              }}
            />
            <label htmlFor="stage_process">Etapa do projeto*</label>
            <select
              id="stage_process"
              name="stage_process"
              value={dados.stage_process}
              onChange={handleChange}
              required
            >
              <option value="" disabled selected>
                Selecione o estágio
              </option>
              {faseProcesso?.map((item, idx) => (
                <option
                  key={idx}
                  value={item}
                  disabled={idx === 0 ? false : true}
                >
                  {item}
                </option>
              ))}
              <option value="outro" disabled>
                Outro
              </option>
            </select>
            {dados.stage_process === 'outro' && (
              <>
                <label htmlFor="stage_process">Nova fase do processo</label>
                <input
                  id="new_stage_process"
                  name="new_stage_process"
                  value={dados.new_stage_process}
                  onChange={handleChange}
                  required
                />
              </>
            )}
            {/* <label htmlFor="name_process">Nome do processo</label>
          <input
            id="name_process"
            name="name_process"
            value={dados.name_process}
            onChange={handleChange}
          /> */}
            <label htmlFor="date_limit">Data limite da etapa*</label>
            <input
              id="date_limit"
              name="date_limit"
              type="date"
              value={dados.date_limit}
              onChange={handleChange}
              required
            />
            <label htmlFor="note">Nota/observação da etapa*</label>
            <textarea
              id="note"
              name="note"
              value={dados.note}
              onChange={handleChange}
              required
            />

            <FielButtons>
              <button className="btn__retornar" onClick={() => props.fechar()}>
                Cancelar
              </button>
              <button className="btn__register" type="submit">
                Confirmar
              </button>
            </FielButtons>
          </div>
        </form>
      ) : (
        <div className="div__corpo">
          <div>
            <label htmlFor="name_project">Enviado!</label>
            <span>Sua solicitação foi realiza com sucesso.</span>
            <div className="div__protocolo">
              {/* <strong>Protocolo</strong> <br /> 9999d99d99d9d4 */}
            </div>
          </div>
          <FielButtons>
            <button
              className="btn__retornar"
              onClick={() => props.fechar()}
              style={{ margin: 'auto' }}
            >
              Fechar
            </button>
          </FielButtons>
        </div>
      )}
      {showbackdrop && <Backdrop />}
    </Container>
  );
}

export default Chatboxconfirm;
