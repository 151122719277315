import React from 'react';
import { toast } from 'react-toastify';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import Imgtrocarsenha from '../../assets/images/icons/trocar_senha.png';
import { Conteudo } from './styles';

function Dialogotrocarsenha({ negar, confirmar }) {
  const [open, setOpen] = React.useState(true);
  const [show, setShow] = React.useState({
    old_password: false,
    new_password: false,
    password_confirmation: false,
  });
  const [dados, setDados] = React.useState({
    old_password: '',
    new_password: '',
    password_confirmation: '',
  });

  const handleClose = () => {
    setOpen(false);
    negar();
  };

  const validatePassword = (e) => {
    if (dados.old_password.length < 1) {
      toast.warn('Preencha corretamente o campo indicado com sua senha atual.');
      return false;
    }

    if (dados.new_password.length > 0) {
      if (dados.new_password.length > 5) {
        if (dados.new_password === dados.password_confirmation) {
          confirmar(dados);
        } else {
          toast.warn('Suas senhas não correspondem.');
          return false;
        }
      } else {
        toast.warn('Sua senha deve conter, no mínimo, 6 caractesres.');
        return false;
      }
    } else {
      toast.warn('Preencha corretamente o campo indicado com sua nova senha.');
      return false;
    }
  };

  return (
    <Dialog
      // fullWidth={fullWidth}
      // maxWidth={maxWidth}
      fullWidth={window.innerWidth < 675 ? true : false}
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      style={{
        zIndex: 9999,
      }}
    >
      <DialogTitle
        id="max-width-dialog-title"
        style={{ fontFamily: "'Poppins', sans-serif !important" }}
      >
        Trocar Senha {dados.name ? dados.name : dados.full_name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Conteudo>
            <img src={Imgtrocarsenha} />
            <div className="div__one_row">
              <input
                autoFocus
                type={show.old_password ? 'text' : 'password'}
                placeholder="Senha atual"
                value={dados.old_password}
                onChange={(a) =>
                  setDados({ ...dados, old_password: a.target.value })
                }
              />{' '}
              <button
                onClick={() =>
                  setShow((prev) => ({
                    ...prev,
                    old_password: !prev.old_password,
                  }))
                }
              >
                {!show.old_password ? (
                  <VisibilityOffIcon />
                ) : (
                  <RemoveRedEyeIcon />
                )}
              </button>
            </div>
            <div className="div__one_row">
              <input
                autoFocus
                type={show.new_password ? 'text' : 'password'}
                placeholder="Digite a nova senha"
                value={dados.new_password}
                onChange={(a) =>
                  setDados({ ...dados, new_password: a.target.value })
                }
              />{' '}
              <button
                onClick={() =>
                  setShow((prev) => ({
                    ...prev,
                    password: !prev.password,
                  }))
                }
              >
                {!show.password ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
              </button>
            </div>
            <div className="div__one_row">
              <input
                autoFocus
                type={show.password_confirmation ? 'text' : 'password'}
                placeholder="Confirme a nova senha"
                value={dados.password_confirmation}
                onChange={(a) =>
                  setDados({ ...dados, password_confirmation: a.target.value })
                }
              />{' '}
              <button
                onClick={() =>
                  setShow((prev) => ({
                    ...prev,
                    password_confirmation: !prev.password_confirmation,
                  }))
                }
              >
                {!show.password_confirmation ? (
                  <VisibilityOffIcon />
                ) : (
                  <RemoveRedEyeIcon />
                )}
              </button>
            </div>
            <ul>
              <li>Senha deve conter no mínimo 6 caracteres</li>
              <li>
                Para uma senha forte, é aconselhável que contenha números,
                letras e caracteres especiais
              </li>
              <li>
                Sua senha e credenciais de acesso são de uso pessoal, evite
                informar a terceiros
              </li>
            </ul>
          </Conteudo>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => negar()} color="" variant="contained">
          Cancelar
        </Button>
        <Button
          onClick={() => validatePassword()}
          // color="secondary"
          variant="contained"
          style={{
            backgroundColor: 'var(--primary2)',
            color: '#fff',
          }}
        >
          Substituir senha
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Dialogotrocarsenha;
