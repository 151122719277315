import styled from "styled-components";

export const Container = styled.div`
  margin-top: 4rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem 1rem;
`;

export const Page = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

  min-height: 200px;

  header {
    width: 100%;
    padding: 1rem 2rem;
    text-align: left;
    font-family: "Poppins", sans-serif;

    h1 {
      font-weight: 800;
      font-size: 1.5rem;
      color: var(--primary2);
      text-transform: uppercase;
      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      opacity: 1;
    }
    span {
      opacity: 0.9;
    }

    ::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      margin-top: 1rem;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
    }
  }

  .div__conteudo {
    margin: 0rem 2rem;
    margin-bottom: 2rem;
    /* background: red; */

    .div__row {
      display: flex;
      flex-direction: row;

      strong {
        margin-right: 0.5rem;
      }
      small {
        margin: 0rem 0.5rem;
      }
    }

    h4 {
      /* background-color: red; */
      text-transform: uppercase;
      margin: 1rem 0rem;
      font-size: 0.8rem;
      color: rgba(var(--black_rgb), 0.5);
      font-weight: 400;
      width: 100%;
      /* padding: 0rem 2rem; */
      flex: 1;
      /* margin-left: 0rem; */
      text-align: left;

      ::after {
        display: block;
        content: "";
        margin: 0.5rem 0rem;
        border-bottom: 1px dotted rgba(var(--black_rgb), 0.5);
      }
    }

    p {
      flex: 1;
      margin: 0rem;
      font-size: 0.9rem;
    }
    label {
      font-weight: 600;
    }
    .div__oneRow {
      display: flex;
      text-align: left;
      justify-content: space-between;
      /* background: red; */
    }

    .btn__finish {
      background-image: linear-gradient(
        to bottom right,
        var(--primary2),
        var(--primary2)
      );
      padding: 0.5rem 2rem;
      color: #fff;
      font-family: "Poppins", sans-serif;
      border: 0rem;
      font-size: 0.8rem;
      float: left;
      margin: 1rem 0rem;
      cursor: pointer;
      border-radius: var(--border-radius);

      :hover {
        background-image: linear-gradient(
          to bottom left,
          var(--primary),
          var(--primary)
        );
      }
    }

    @media screen and (max-width: 675px) {
      .div__oneRow {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;
