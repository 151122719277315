import React, { useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { toast } from 'react-toastify';

import { Container, FielButtons } from './styles';
import api from '../../services/api';

export default function ModalRecoverPassword(props) {
  const [email, setEmail] = React.useState('');

  const onSubmit = useCallback(async (event) => {
    event.preventDefault();

    await api
      .post(`/users/password/recovery-email`, { email })
      .then(() => {
        toast.success('E-mail para recuperar senha foi enviado com sucesso!');
      })
      .catch((error) => {
        console.error(error);
        toast.warn('Não foi possível enviar o email de recuperação de senha');
      });

    props.fechar();
  }, [email, props]);

  return (
    <div>
      <Dialog
        open={true}
        maxWidth="md"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 9999999991,
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Container>
              <header>
                <h1>Recuperação de Senha</h1>
                <span>
                  Digite seu email cadastrado para que se possa realizar a
                  recuperação.
                </span>
              </header>
              <form
                className="div__oneRow"
                onSubmit={onSubmit}
              >
                <input
                  type="text"
                  placeholder="E-mail"
                  autoFocus
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button className="btn__register" type="submit">
                  Enviar
                </button>
              </form>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            <button
              onClick={() => props.fechar()}
              className="btn__retornar"
              color="primary"
              style={{
                minWidth: '100px',
              }}
            >
              Voltar
            </button>
          </FielButtons>
        </DialogActions>
      </Dialog>
    </div>
  );
}
