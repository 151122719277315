import { toast } from 'react-toastify';
import api from './api';

// configurações;
var config = {
  headers: {
    Authorization: '',
    'Content-Type': 'application/json',
  },
};

export async function SET_TOKEN(new_token) {
  config.headers.Authorization = `Bearer ${new_token}`;
  return;
}

// COMPANIES
export async function GET_COMPANIES(setValue, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .get(`/companies`, config.headers.Authorization && config)
    .then((res) => {
      setValue(res.data);
      setDrop && setDrop(true);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
      setDrop && setDrop(true);
    });
}

// COMPANIES
export async function GET_COMPANIES_DATA(setValue, setDrop) {
  (await setDrop) && setDrop(true);
  //  console.log('');
  // await console.log('Vindo do cabeçalho: ', config?.headers?.Authorization);

  await api
    .get(`/companies/data`, config.headers.Authorization && config)
    .then((res) => {
      setValue(res.data);
      setDrop && setDrop(false);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      setDrop && setDrop(false);
    });
}

export async function PUT_COMPANIES(
  id,
  {
    address_id,
    fantasy_name,
    social_reason,
    cnpj,
    municipal_registration,
    state_registration,
  },
  closeModal,
  setDrop
) {
  (await setDrop) && setDrop(true);
  await api
    .put(
      `/companies/${id}`,
      {
        address_id,
        fantasy_name,
        social_reason,
        cnpj,
        municipal_registration,
        state_registration,
      },
      config.headers.Authorization && config
    )
    .then((res) => {
      toast.success(`Dados atualizado com sucesso`);
      setDrop && setDrop(false);
      closeModal();
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

// PROJECTS
export async function GET_PROJECTS(setValue, setDrop, filter) {
  const params = new URLSearchParams()

  if (filter?.pages) {
    params.append('page', filter.pages ?? 0)
  }
  if (filter?.rows) {
    params.append('rows', filter.rows ?? 10)
  }
  if (filter?.dateOrder) {
    params.append('date_order', filter.dateOrder) // 'ASC' | 'DESC'
  }
  if (filter?.clientNameOrder) {
    params.append('client_name_order', filter.clientNameOrder)
  }
  if (filter?.hasDeadline) {
    params.append('has_deadline', filter.hasDeadline)
  }
  if (filter?.hasResponsibles) {
    params.append('has_responsibles', filter.hasResponsibles)
  }
  if (filter?.hasStages) {
    params.append('has_stages', filter.hasStages)
  }
  if (filter?.status) {
    params.append('status', filter.status)
  }
  if (filter?.startDate) {
    params.append('start_date', filter.startDate)
  }
  if (filter?.endDate) {
    params.append('end_date', filter.endDate)
  }
  if (filter?.isFinished) {
    params.append('is_finished', filter.isFinished)
  }
  if (filter?.clientName) {
    params.append('client_name', filter.clientName)
  }
  if (filter?.companyName) {
    params.append('company_name', filter.companyName)
  }
  if (filter?.stageTitle) {
    params.append('stage_title', filter.stageTitle)
  }

  (await setDrop) && setDrop(true)
  await api
    .get(`/projects?${params}`, config.headers.Authorization && config)
    .then((res) => {
      setValue(res.data)
      setDrop && setDrop(false)
    })
    .catch((error) => {
      toast.error(error.response.data.message)
      setDrop && setDrop(false)
    })
}

export async function GET_PROJECTS_BY_COMPANY(id, setValue, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .get(`/projects/company/${id}`, config.headers.Authorization && config)
    .then((res) => {
      setValue && setValue(res.data);
      setDrop && setDrop(false);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

export async function PATCH_PROJECTS_BY_ID(id, AtualizarValores, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .patch(`/projects/suspend/${id}`, config.headers.Authorization && config)
    .then((res) => {
      // setDrop && setDrop(false);
      AtualizarValores();
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

export async function PATCH_PROJECTS_REFUSE(dados, AtualizarValores, setDrop) {
  // (await setDrop) && setDrop(true);
  await api
    .patch(
      `/projects/refuse/${dados.id}`,
      config.headers.Authorization && config
    )
    .then((res) => {
      // setDrop && setDrop(false);
      AtualizarValores();
      toast.success(
        `Projeto de ${dados.client_name} foi ${
          !dados.refused ? 'recusado' : 'movido para Novos Projetos'
        }`
      );
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

export async function PATCH_FINISHED_PROJECT(
  id,
  body,
  AtualizarValores,
  setDrop
) {
  (await setDrop) && setDrop(true);

  await api
    .patch(`/projects/finished/${id}`, body)
    .then((res) => {
      AtualizarValores();
      setDrop && setDrop(false);
      // toast.success('Projeto finalizado com sucesso!');
    }, config.headers.Authorization && config)
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

export async function GET_DEALER_BY_STATE(state, setValue, setDrop) {
  setDrop && setDrop(true);
  await api
    .get(`/dealers/state/${state}`)
    .then((res) => {
      setValue(res.data);
      setDrop && setDrop(false);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

export async function GET_DEALERS(setValue, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .get(`/dealers`, config.headers.Authorization && config)
    .then((res) => {
      setValue(res.data);
      setDrop && setDrop(false);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

export async function PUT_PROJECTS(
  id,
  {
    dealer_id,
    client_cnpj,
    client_name,
    breaker_type,
    breaker_value,
    latitude,
    longitude,
    has_order_material,
    has_apportionment,
    exchange_ownership,
    total_project_power,
    amount_panels,
    panel_power,
    panel_model,
  },
  AtualizarValores,
  setValue,
  setDrop
) {
  (await setDrop) && setDrop(true);
  await api
    .put(
      `/projects/${id}`,
      {
        dealer_id,
        client_cnpj,
        client_name,
        breaker_type,
        breaker_value,
        latitude,
        longitude,
        has_order_material,
        has_apportionment,
        exchange_ownership,
        total_project_power,
        amount_panels,
        panel_power,
        panel_model,
      },
      config.headers.Authorization && config
    )
    .then((res) => {
      AtualizarValores();
      setValue(res.data);
      setDrop && setDrop(false);
      toast.success(`Projeto atualizado com sucesso`);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

// DOCUMENTS
export async function POST_NEW_DOCUMENT(
  formData,
  setValue,
  AtualizarValores,
  setDrop
) {
  (await setDrop) && setDrop(true);

  await api
    .post(
      `documents`,
      formData,
      config.headers.Authorization && {
        headers: { ...config.headers, 'Content-Type': 'multipart/form-data' },
      }
    )
    .then((res) => {
      setValue((value) => {
        return [...value, { ...res.data }];
      });
      AtualizarValores();
      setDrop && setDrop(false);
      toast.success('Novo arquivo adicionado com sucesso!');
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
      // setDrop && setDrop(false);
    });
}

export async function DELETE_DOCUMENT_BY_ID(id, AtualizarValores, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .delete(`/documents/${id}`, config.headers.Authorization && config)
    .then((res) => {
      AtualizarValores();
      setDrop && setDrop(false);
      toast.success(`Arquivo removido com sucesso`);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

export async function PATCH_DOCUMENT_APROVE(dados, AtualizarValores, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .patch(
      `/documents/approve/${dados.id}`,
      config.headers.Authorization && config
    )
    .then((res) => {
      AtualizarValores();
      setDrop && setDrop(false);
      toast.success(`Arquivo ${dados.title} Aceito com sucesso`);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

// INVERTERS
export async function PUT_INVERTERS_BY_ID(
  id,
  { power_kwp, model, amount },
  AtualizarValores
  // setDrop
) {
  // setDrop && setDrop(true);
  await console.log('');
  await api
    .put(
      `/inverters/${id}`,
      {
        power_kwp,
        model,
        amount,
      },
      config.headers.Authorization && config
    )
    .then((res) => {
      AtualizarValores && AtualizarValores();
      // setDrop && setDrop(true);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
    });
}

export async function POST_INVERTERS(
  { project_id, power_kwp, model, amount },
  AtualizarValores,
  setValue,
  setDrop
) {
  (await setDrop) && setDrop(true);
  await api
    .post(
      `/inverters`,
      {
        project_id,
        power_kwp,
        model,
        amount,
      },
      config.headers.Authorization && config
    )
    .then((res) => {
      setValue((value) => {
        return [{ ...res.data }, ...value];
      });
      setDrop && setDrop(false);
      toast.success(`Inversor adicionado com sucesso`);
      AtualizarValores();
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

export async function DELETE_INVERTERS(id, AtualizarValores, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .delete(`/inverters/${id}`, config.headers.Authorization && config)
    .then((res) => {
      AtualizarValores && AtualizarValores();
      setDrop && setDrop(false);
      toast.success(`Inversor removido com sucesso`);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

//PENDENCIES
export async function POST_PENDENCIES(
  body,
  AtualizarValores,
  setValue,
  setDrop
) {
  (await setDrop) && setDrop(true);
  await api
    .post(`/pendencies`, body, config.headers.Authorization && config)
    .then((res) => {
      setValue((value) => {
        return [...value, { ...res.data }];
      });
      AtualizarValores();
      setDrop && setDrop(false);
      toast.success(`Pendência cadastrada com sucesso`);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

export async function PATCH_PENDENCIES(dados, AtualizarValores, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .patch(
      `/pendencies/resolve/${dados.id}`,
      config.headers.Authorization && config
    )
    .then((res) => {
      AtualizarValores();
      setDrop && setDrop(false);
      toast.success(`Pendência confirmada como resolvida.`);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

export async function DELETE_PENDENCIES(id, AtualizarValores, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .delete(`/pendencies/${id}`, config.headers.Authorization && config)
    .then((res) => {
      AtualizarValores();
      setDrop && setDrop(false);
      toast.success(`Pendência removida com sucesso`);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

// CHANGE REQUESTS
export async function PATCH_CHANGE_REQUESTS(
  id,
  AtualizarValores,
  setValue,
  setDrop
) {
  (await setDrop) && setDrop(true);
  await api
    .patch(
      `/project-change-requests/resolve/${id}`,
      config.headers.Authorization && config
    )
    .then((res) => {
      setValue((value) => {
        return value?.map((dado) =>
          res.data.id === dado.id ? res.data : dado
        );
      });
      AtualizarValores();
      setDrop && setDrop(false);
      toast.success(`Alteração confirmada com sucesso`);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

export async function DELETE_CHANGE_REQUESTS(id, AtualizarValores, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .delete(
      `/project-change-requests/${id}`,
      config.headers.Authorization && config
    )
    .then((res) => {
      AtualizarValores();
      setDrop && setDrop(false);
      toast.warn(`Pedido de alteração deletado`);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

//STATE_PROJECT

export async function POST_STAGE(
  body,
  description,
  AtualizarValores,
  setDrop,
  id_lastStage
) {
  (await setDrop) && setDrop(true);
  await api
    .post(`/stages`, body, config.headers.Authorization && config)
    .then((res) => {
      POST_STAGE_STATUS({ stage_id: res.data.id, description });
      id_lastStage &&
        PATCH_FINISH_STAGE(id_lastStage, { finished_at: res.data.created_at });
      AtualizarValores();
      setDrop && setDrop(false);
      toast.success(`Etapa cadastrada com sucesso`);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

export async function PUT_STAGE(id, body, AtualizarValores, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .put(`/stages/${id}`, body, config.headers.Authorization && config)
    .then((res) => {
      AtualizarValores();
      setDrop && setDrop(false);
      toast.success('Etapa alterada com sucesso!');
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

export async function POST_STAGE_STATUS(body, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .post(`/status`, body, config.headers.Authorization && config)
    .then((res) => {
      setDrop && setDrop(false);
      // AtualizarValores();
      // toast.success(`Status cadastrada com sucesso`);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

export async function PATCH_FINISH_STAGE(id, body) {
  // await console.log('');
  await api
    .patch(
      `/stages/finished/${id}`,
      body,
      config.headers.Authorization && config
    )
    // .then((res) => {
    //   // console.log('PATCH_FINISH_STAGE: ', res.data);
    // })
    .catch((error) => {
      toast.error(error.response.data.message);
    });
}

// RESPONSIBLES

export async function GET_RESPONSIBLES(setValue, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .get(`/users/list-users`, {
      params: {
        roles: JSON.stringify(['ADMINISTRADOR', 'GERENTE']),
      },
    })
    .then((res) => {
      setValue(res.data);
      setDrop && setDrop(false);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

export async function DELETE_RESPONSIBLES(id, atualizarValores, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .delete(`/responsibles/${id}`, config.headers.Authorization && config)
    .then((res) => {
      toast.success(`Supervisor removido com sucesso.`);
      atualizarValores();
      // setDrop && setDrop(false);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

export async function POST_RESPONSIBLES(body, AtualizarValores, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .post(`/responsibles`, body)
    .then((res) => {
      AtualizarValores();
      setDrop && setDrop(false);
      toast.success(`Responsável cadastrado com sucesso`);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

// PAYMENT
export async function SET_PAYMENT_ID(id_receipt, setValue, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .patch(
      `/receipts/confirm-payment/${id_receipt}`,
      config.headers.Authorization && config
    )
    .then((res) => {
      setValue && setValue();
      setDrop && setDrop(false);
      toast.success('Pagamento confirmado com sucesso!');
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

export async function SET_CANCEL_PAYMENT_ID(id_receipt, setValue, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .patch(
      `/receipts/cancel-payment/${id_receipt}`,
      config.headers.Authorization && config
    )
    .then((res) => {
      setValue && setValue();
      setDrop && setDrop(false);
      toast.success('Pagamento cancelado com sucesso!');
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

// ADDRESS
export async function SHOW_ADDRESS(id, setValue, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .get(`/addresses/${id}`, config.headers.Authorization && config)
    .then((res) => {
      setValue(res.data);
      setDrop && setDrop(false);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

export async function POST_COMPANY_ADDRESS(
  body,
  setValue,
  // setCards,
  setAddress,
  setDrop,
  edit
) {
  (await setDrop) && setDrop(true);
  await api
    .post(`/addresses/of-company`, body, config.headers.Authorization && config)
    .then((res) => {
      setValue && setValue(res.data);
      setAddress && SHOW_ADDRESS(res.data.address_id, setAddress, setDrop);
      setDrop && setDrop(false);
      !edit && toast.success('Endereço cadastrado com sucesso!');
      edit && toast.success(`Dados atualizado com sucesso`);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

// USUÁRIOS DA PLATAFORMA

export async function GET_USERS_COMPANY(setValue, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .get(`/users/admin/list-users`, {
      params: {
        roles: JSON.stringify(['ADMINISTRADOR', 'GERENTE']),
      },
    })
    .then((res) => {
      setValue(res.data);
      setDrop && setDrop(false);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setDrop && setDrop(false);
    });
}

export async function DELETE_USER_COMPANY(setValue, setDrop, atualizar) {
  (await setDrop) && setDrop(true);
  await api
    .delete(`/users/admin/delete-user/${setValue}`)
    .then((res) => {
      setDrop && setDrop(false);
      atualizar();
    })
    .catch((error) => {
      toast.error(error.response.error);
      setDrop && setDrop(false);
    });
}

export async function SET_NEW_USERS_COMPANY(
  setValue,
  setDrop,
  atualizar,
  handleClose
) {
  (await setDrop) && setDrop(true);
  await api
    .post(`/users/admin/create-user`, setValue)
    .then((res) => {
      setDrop && setDrop(false);

      toast.success(`Usuário ${setValue?.name} adicionado com sucesso!`);

      atualizar();

      handleClose();
    })
    .catch((error) => {
      toast.error(error.response.data.error);
      setDrop && setDrop(false);
    });
}

export async function EDIT_USER_COMPANY(
  setValue,
  setDrop,
  atualizar,
  handleClose
) {
  (await setDrop) && setDrop(true);
  await api
    .put(`/users/admin/update-user/${setValue.user_id}`, setValue)
    .then((res) => {
      setDrop && setDrop(false);

      toast.success(`Usuário ${setValue.name} editado com sucesso!`);

      atualizar();

      handleClose();
    })
    .catch((error) => {
      toast.error(error.response.data.error);
      setDrop && setDrop(false);
    });
}

// RECIBOS

export async function GET_RECEIPTS(
  setValue,
  setDrop,
  page,
  rows,
  setTotalpage
) {
  (await setDrop) && setDrop(true);
  await api
    .get(
      `/receipts?page=${page}&rows=${rows}`,
      config.headers.Authorization && config
    )
    .then((res) => {
      setValue(res.data.receipts);
      setTotalpage(res.data.itens.total);
      setDrop && setDrop(false);
    })
    .catch((error) => {
      // toast.error(error.response.error);
      console.log('erro recibos:', error.response);
      setDrop && setDrop(false);
    });
}

// ORÇAMENTOS
export async function GET_BUDGETING(
  setValue,
  setDrop,
  page,
  rows,
  setTotalpage
) {
  (await setDrop) && setDrop(true);
  await api
    .get(`/budgeting`, config.headers.Authorization && config)
    .then((res) => {
      setValue(res.data);
      // setTotalpage(res.data.itens.total);
      setDrop && setDrop(false);
    })
    .catch((error) => {
      // toast.error(error.response.error);
      console.log('erro recibos:', error.response);
      setDrop && setDrop(false);
    });
}

// DASHBOARD

export async function GET_PROJECTS_DELAYED(setValue, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .get(`/projects/delayed`, config.headers.Authorization && config)
    .then((res) => {
      setValue(res.data);
      // setTotalpage(res.data.itens.total);
      setDrop && setDrop(false);
    })
    .catch((error) => {
      // toast.error(error.response.error);
      console.log('erro recibos:', error.response);
      setDrop && setDrop(false);
    });
}

export async function GET_DEALERS_DATA(setValue, dados, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .get(
      `/dealers/data?filter=${dados.filter}`,
      config.headers.Authorization && config
    )
    .then((res) => {
      setValue(res.data);
      // setTotalpage(res.data.itens.total);
      setDrop && setDrop(false);
    })
    .catch((error) => {
      // toast.error(error.response.error);
      console.log('erro recibos:', error.response);
      setDrop && setDrop(false);
    });
}

export async function GET_RECEIPTS_AMOUNT_BY_DATE(setValue, dados, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .get(
      `/receipts/amount-by-date?start_date=${dados.start_date}&end_date=${dados.end_date}&month=${dados.month}&day=${dados.day}`,
      config.headers.Authorization && config
    )
    .then((res) => {
      setValue(res.data);
      // setTotalpage(res.data.itens.total);
      setDrop && setDrop(false);
    })
    .catch((error) => {
      // toast.error(error.response.error);
      console.log('erro recibos:', error.response);
      setDrop && setDrop(false);
    });
}

export async function GET_REVENUE_BY_DATE(setValue, dados, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .get(
      `/receipts/revenue-by-date?start_date=${dados.start_date}&end_date=${dados.end_date}&month=${dados.month}&day=${dados.day}`,
      config.headers.Authorization && config
    )
    .then((res) => {
      setValue(res.data);
      // setTotalpage(res.data.itens.total);
      setDrop && setDrop(false);
    })
    .catch((error) => {
      // toast.error(error.response.error);
      console.log('erro recibos:', error.response);
      setDrop && setDrop(false);
    });
}

export async function GET_BUDGETING_BY_DATE(setValue, dados, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .get(
      `/budgeting/amount-by-date?start_date=${dados.start_date}&end_date=${dados.end_date}&month=${dados.month}&day=${dados.day}`,
      config.headers.Authorization && config
    )
    .then((res) => {
      setValue(res.data);
      // setTotalpage(res.data.itens.total);
      setDrop && setDrop(false);
    })
    .catch((error) => {
      // toast.error(error.response.error);
      console.log('erro recibos:', error.response);
      setDrop && setDrop(false);
    });
}

export async function GET_RECEIPTS_TOTAL(setValue, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .get(`/receipts/total`, config.headers.Authorization && config)
    .then((res) => {
      setValue(res.data);
      // setTotalpage(res.data.itens.total);
      setDrop && setDrop(false);
    })
    .catch((error) => {
      // toast.error(error.response.error);
      console.log('erro recibos:', error.response);
      setDrop && setDrop(false);
    });
}

export async function GET_COMPANY_TOTAL(setValue, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .get(`/companies/total`, config.headers.Authorization && config)
    .then((res) => {
      setValue(res.data);
      // setTotalpage(res.data.itens.total);
      setDrop && setDrop(false);
    })
    .catch((error) => {
      // toast.error(error.response.error);
      console.log('erro recibos:', error.response);
      setDrop && setDrop(false);
    });
}

export async function PATCH_PIX_CODE_PROJECT(id, body, setDrop) {
  (await setDrop) && setDrop(true);
  await api
    .patch(`/projects/update-pix-code/${id}`, body)
    .then((res) => {
      setDrop && setDrop(false);
      // toast.success('Projeto finalizado com sucesso!');
    }, config.headers.Authorization && config)
    .catch((error) => {
      // toast.error(error.response.error);
      console.log('erro recibos:', error.response);
      setDrop && setDrop(false);
    });
}
