import React, { useState, useEffect } from 'react';
import { mask } from 'remask';

import { Container, Page } from './styles';

// icones
import { BsSortAlphaDown, BsSortAlphaUpAlt } from 'react-icons/bs';

import CardIntegradores from '../../../components/CardIntegradores';
import { GET_COMPANIES_DATA } from '../../../services/endpoints';
import SimpleBackdrop from '../../../components/Backdrop';

// contexto
import { useAuth } from '../../../contexts/AuthContext';

function Integradores() {
  const [showbackdrop, setShowbackdrop] = useState(false);
  const { user } = useAuth();

  const [filter, setFilter] = useState({
    type: '',
    value: '',
    dig: '',
  });
  const [listidx, setListidx] = useState(4);
  const [order, setOrder] = useState('');

  const [listIntegrators, setListIntegrators] = useState(null);

  const handleFilter = (dado) => {
    if (filter.type === 'integrador') {
      if (
        dado.social_reason.toLowerCase().indexOf(filter.value.toLowerCase()) >
        -1
      )
        return true;
    } else if (filter.type === 'cpf') {
      if (
        dado.owner.cpf
          .replace(/[^0-9]/g, '')
          .indexOf(filter.value.replace(/[^0-9]/g, '')) > -1
      )
        return true;
    } else if (filter.type === 'cnpj') {
      if (
        dado?.cnpj
          ?.replace(/[^0-9]/g, '')
          .indexOf(filter.value.replace(/[^0-9]/g, '')) > -1
      )
        return true;
    } else if (filter.type === 'open_projects') {
      if (dado.open_projects) return true;
    } else if (filter.type === 'completed_projects') {
      if (dado.fineshed_projects) return true;
    } else if (filter.type === 'suspended_projects') {
      // console.log('Entrando aqui: ', dado.max_process);
      if (dado.suspended_projects) return true;
    } else if (filter.type === 'email') {
      if (
        dado?.owner?.email.toLowerCase().indexOf(filter.value.toLowerCase()) >
        -1
      )
        return true;
    } else {
      return true;
    }
  };

  const handleFilterValue = (event) => {
    if (filter.type === 'cpf') {
      setFilter({
        ...filter,
        value: mask(event.target.value, '999.999.999-99'),
      });
    } else if (filter.type === 'cnpj') {
      setFilter({
        ...filter,
        value: mask(event.target.value, '99.999.999/9999-99'),
      });
    } else {
      setFilter({ ...filter, value: event.target.value });
    }
  };

  useEffect(() => {
    GET_COMPANIES_DATA(setListIntegrators, setShowbackdrop);
  }, []);

  let filterIntegrators = listIntegrators

    ?.filter(handleFilter)
    ?.sort((a, b) => {
      if (order === 'AZ') {
        return a.social_reason > b.social_reason
          ? 1
          : b.social_reason > a.social_reason
          ? -1
          : 0;
      } else if (order === 'ZA') {
        return a.social_reason < b.social_reason
          ? 1
          : b.social_reason < a.social_reason
          ? -1
          : 0;
      } else if (order === 'primeiros') {
        return new Date(b.created_at) - new Date(a.created_at);
      } else if (order === 'ultimos') {
        return new Date(a.created_at) - new Date(b.created_at);
      }
    });

  // useEffect(() => {
  //   filterIntegrators = listIntegrators?.filter(handleFilter)?.sort((a, b) => {
  //     if (order === 'AZ') {
  //       return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
  //     } else if (order === 'ZA') {
  //       return a.name < b.name ? 1 : b.name < a.name ? -1 : 0;
  //     } else if (order === 'primeiros') {
  //       return new Date(b.created_at) - new Date(a.created_at);
  //     } else if (order === 'ultimos') {
  //       return new Date(a.created_at) - new Date(b.created_at);
  //     }
  //   });
  //   console.log('ENTROU NO EFFECT!!');
  // }, [listIntegrators]);

  return (
    <Container>
      <Page>
        <header>
          <h1>Integradores</h1>
          <span>Lista de integradores cadastrados na plataforma.</span>
        </header>
        <content>
          <div className="div__fieldFilter">
            <div className="div__filters">
              <select
                onChange={(event) =>
                  setFilter({
                    ...filter,
                    type: event.target.value,
                    value: '',
                    dig: '',
                  })
                }
                value={filter.type}
                style={{ maxWidth: 200 }}
              >
                <option value="" selected>
                  Filtro
                </option>
                <option value="integrador">Integrador</option>
                <option value="cpf">CPF</option>
                <option value="cnpj">CNPJ</option>
                <option value="email">E-mail</option>
                {/* <option value="state">Estado</option> */}
                {/* <option value="concessionaria">Concessionária</option> */}
                <option value="open_projects">Projetos em aberto</option>
                {/* <option value="delayed_projects">Projetos atrasados</option> */}
                <option value="completed_projects">Projetos finalizados</option>
                <option value="suspended_projects">Projetos suspensos</option>
              </select>

              {/* autocomplete */}

              <input
                placeholder={
                  filter.type === 'integrador'
                    ? 'Digite o nome'
                    : filter.type === 'email'
                    ? 'Digite o E-mail'
                    : filter.type === 'cpf'
                    ? 'Digite o CPF'
                    : filter.type === 'cnpj'
                    ? 'Digite o CNPJ'
                    : ' - '
                }
                value={filter.value}
                disabled={!filter.type ? true : false}
                onChange={handleFilterValue}
                style={{
                  display:
                    filter.type === 'integrador' ||
                    filter.type === 'email' ||
                    filter.type === 'cpf' ||
                    filter.type === 'cnpj'
                      ? 'block'
                      : 'none',
                }}
              />

              <select
                required
                name="state"
                style={{
                  flex: 1,
                  display: filter.type === 'state' ? 'block' : 'none',
                }}
                value={filter.value}
                onChange={handleFilterValue}
              >
                <option value="" disabled selected>
                  Escolha o estado
                </option>
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AP">AP</option>
                <option value="AM">AM</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MT">MT</option>
                <option value="MS">MS</option>
                <option value="MG">MG</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PR">PR</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RS">RS</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="RC">SC</option>
                <option value="SP">SP</option>
                <option value="SE">SE</option>
                <option value="TO">TO</option>
              </select>

              {/* fim de autocomplete */}
            </div>
            <div className="div__order">
              <button
                className={order === 'AZ' ? 'btn__esq btn__ativ' : 'btn__esq'}
                onClick={() => (order === 'AZ' ? setOrder('') : setOrder('AZ'))}
              >
                <BsSortAlphaDown style={{ fontSize: '1.4rem' }} />
              </button>
              <button
                className={order === 'ZA' ? 'btn_meio btn__ativ' : 'btn_meio'}
                onClick={() => (order === 'ZA' ? setOrder('') : setOrder('ZA'))}
              >
                <BsSortAlphaUpAlt style={{ fontSize: '1.4rem' }} />
              </button>

              <button
                className={
                  order === 'primeiros' ? 'btn_meio btn__ativ' : 'btn_meio'
                }
                onClick={() =>
                  order === 'primeiros' ? setOrder('') : setOrder('primeiros')
                }
              >
                Recentes
              </button>
              <button
                className={
                  order === 'ultimos' ? 'btn__dir btn__ativ' : 'btn__dir'
                }
                onClick={() =>
                  order === 'ultimos' ? setOrder('') : setOrder('ultimos')
                }
              >
                Antigos
              </button>
            </div>
          </div>

          <div className="div__deck">
            {filterIntegrators?.slice(0, listidx)?.map((integrator) => (
              <CardIntegradores
                key={integrator.id}
                dados={integrator}
                atualizar={async () => {
                  await GET_COMPANIES_DATA(setListIntegrators, setShowbackdrop);
                }}
              />
            ))}
          </div>
        </content>

        {filterIntegrators?.length > listidx ? (
          <div
            className="div__viewMore"
            onClick={() => setListidx(listidx + 4)}
          >
            Ver mais
          </div>
        ) : filterIntegrators?.length === 0 && filter.type ? (
          <div style={{ margin: '2rem 1rem' }}>
            Nenhum Integrador encontrado
          </div>
        ) : filterIntegrators?.length === 0 && !filter.type ? (
          <div style={{ margin: '2rem 1rem' }}>
            Nenhum Integrador cadastrado
          </div>
        ) : (
          <div style={{ margin: '2rem 1rem' }}>Sem mais Integradores</div>
        )}
      </Page>
      {showbackdrop && <SimpleBackdrop />}
    </Container>
  );
}

export default Integradores;
