import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { Container, FielButtons } from './styles';
import { GET_RESPONSIBLES, POST_RESPONSIBLES } from '../../services/endpoints';
import SimpleBackdrop from '../Backdrop';

export default function Dialogoadicionarsupervisor(props) {
  const [showbackdrop, setShowbackdrop] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [listResponsibles, setListResponsibles] = React.useState(null);
  const [dados, setDados] = React.useState({
    project_id: props.dados.id,
    user_id: '',
  });

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  function onInputChange({ target }) {
    setDados({ ...dados, [target.name]: [target.value] });
  }
  React.useEffect(() => {
    GET_RESPONSIBLES(setListResponsibles, setShowbackdrop);
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        maxWidth="md"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 99999,
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Container suspended={props.dados.suspended}>
              <header>
                <h1>Adicionar Supervisor do Projeto</h1>
                <span>Confira abaixo os supervisores disponivéis.</span>
                <div className="div__oneRow">
                  <select
                    required
                    name="user_id"
                    style={{ flex: 1 }}
                    value={dados.user_id}
                    onChange={onInputChange}
                  >
                    <option value="" disabled selected>
                      Supervisor
                    </option>
                    {listResponsibles?.map((item) => (
                      <option key={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                  <div className="div__moreFiles">
                    <button
                      type="button"
                      className="btn__newFile"
                      onClick={async () => {
                        await POST_RESPONSIBLES(
                          {
                            project_id: Number(dados.project_id),
                            user_id: Number(dados.user_id),
                          },
                          props.atualizar,
                          setShowbackdrop
                        );
                        handleClose();
                      }}
                    >
                      Cadastrar Supervidor
                    </button>
                  </div>
                </div>
              </header>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            <button
              onClick={handleClose}
              className="btn__retornar"
              color="primary"
              style={{
                minWidth: '100px',
              }}
            >
              Voltar
            </button>
          </FielButtons>
        </DialogActions>
        {showbackdrop && <SimpleBackdrop />}
      </Dialog>
    </div>
  );
}
