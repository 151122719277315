import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Container, FielButtons } from './styles';
import { PATCH_PIX_CODE_PROJECT } from '../../services/endpoints';
import { toast } from 'react-toastify';

export default function DialogoPixCode(props) {
  const [open, setOpen] = React.useState(props.status ? props.status : true);

  const [qrCode, setQrCode] = React.useState(props.pixCode ? props.pixCode: '');

  const handleUpdate = async () => {
    await PATCH_PIX_CODE_PROJECT(props.id, {pix_code: qrCode}, props.drop)
    toast.success('Pix cadastrado com sucesso!');
    props.handleSetViewPixCode({status: false})
  };

  const handleClear = async () => {
    await PATCH_PIX_CODE_PROJECT(props.id, {pix_code: ""}, props.drop)
    toast.success('Pix removido com sucesso!');
    props.handleSetViewPixCode({status: false})
  };

  const handleClose = () => {
    props.handleSetViewPixCode({status: false})
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={props.width ? props.width : 'sm'}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: 9999999999 }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontFamily: "'Poppins', sans-serif",
            color: 'var(--primary2)',
          }}
        >
          {props.pixCode ? 'Editar Pix': 'Adicionar Pix'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontFamily: "'Poppins', sans-serif"}}
          >
            <input
                style={{width: "100%"}}
                id="input_qr_code"
                name="new_stage_process"
                value={qrCode}
                onChange={(e)=> setQrCode(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons delete={props.delete}>
            {props.pixCode && (
              <button
              style={{backgroundColor: 'red', color: 'white'}}
              onClick={handleClear}
              className="btn__retornar"
              color="primary"
              >
                Remover Pix
              </button>
            )}
            <button
              onClick={handleClose}
              className="btn__retornar"
              color="primary"
            >
              Cancelar
            </button>
            <button
              onClick={handleUpdate}
              className="btn__register"
            >
              Adicionar
            </button> 

          </FielButtons>
        </DialogActions>
      </Dialog>
    </div>
  );
}
