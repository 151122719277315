import styled from "styled-components";

export const Container = styled.div`
  margin-top: 4rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem 1rem;

  .MuiBadge-colorSecondary {
    color: #fff !important;
    /* background-color: #f50057; */
  }
`;

export const Page = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0rem;
  min-height: 200px;

  header {
    width: 100%;
    padding: 1rem 2rem;
    text-align: left;
    font-family: "Poppins", sans-serif;

    h1 {
      font-weight: 800;
      font-size: 1.5rem;
      color: var(--primary2);
      text-transform: uppercase;
      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      opacity: 1;
    }
    span {
      opacity: 0.9;
    }

    ::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      margin-top: 1rem;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
    }
  }

  .div__deck {
    display: flex;
    flex-direction: column;
  }

  .div__fieldFilter {
    margin: 0rem 2rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;

    .div__filters {
      display: flex;
      justify-content: flex-start;
      margin: auto 0px;
      width: 100%;
      input,
      select {
        outline-color: var(--primary2);
        padding: 0.75rem 0.5rem;
        border: 1px solid rgba(var(--black_rgb), 0.3);
        margin: 0rem 0.25rem;
        width: 100%;
        /* margin: auto; */
        max-width: 350px;
        z-index: 0;
      }

      button {
        background-color: transparent !important;
        border-radius: var(--border-radius);

        padding: 0.2rem 0.5rem;
        color: var(--primary2);
        cursor: pointer;
        min-height: 2.1106rem;

        border: 0;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);

        :hover {
          background-color: var(--primary2) !important;
          color: var(--white);
        }
      }
    }

    .div__order {
      /* background: red; */
      margin: auto;
      display: flex;
      button {
        border: 1px solid rgba(var(--black_rgb), 0.2);
        padding: 0.2rem 0.5rem;
        color: var(--primary2);
        cursor: pointer;

        :hover {
          background-color: var(--primary2) !important;
          color: var(--white);
        }
      }
      .btn__esq {
        background-color: transparent !important;
        border-radius: 0rem;
      }
      .btn__dir {
        background-color: transparent !important;
        border-radius: 0rem;
        border-left: 0px;
      }

      .btn__ativ {
        background-color: var(--primary2) !important;
        color: var(--white);
      }
      .btn_meio {
        background: var(--white);
        border-left: 0px;
      }
    }
  }

  .div__viewMore {
    border: 1px solid rgba(var(--black_rgb), 0.1);
    text-align: center;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 0rem 0rem 0.5rem 0.5rem;
    cursor: pointer;
    font-weight: 500;
    color: rgba(var(--black_rgb), 0.4);

    :hover {
      box-shadow: 0px -10px 20px rgba(var(--black_rgb), 0.1);
    }
  }

  @media screen and (max-width: 675px) {
    .div__fieldFilter {
      flex-direction: column;

      .div__filters {
        flex-direction: column;

        input,
        select {
          width: 100%;
          max-width: 500px !important;
          margin: 0.25rem 0rem;
          height: 3rem;
          padding: 0rem 1rem;
          font-size: 1rem;
        }
      }
      .div__order {
        margin-top: 1rem;
      }
    }
  }
`;

export const Card = styled.div`
  margin: 0.25rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(var(--black_rgb), 0.1);
  font-family: "Poppins", sans-serif;
  background-color: ${(props) =>
    props.suspended ? "rgba(var(--black_rgb), 0.05)" : "white"};

  border: ${(props) =>
    (props.suspended || props.finished) &&
    "1px solid rgba(var(--black_rgb), 0.05)"};

  h4 {
    margin: 0rem;

    svg {
      font-size: 1rem;
      margin-bottom: -0.17rem;
    }
  }

  .tag {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .tag p {
    display: inline-block;
    padding: 0.3rem 0.5rem;
    font-size: 0.7rem;
    border-radius: var(--border-radius);
    text-align: center;
    background-color: ${(props) =>
      props.finished ? "gray" : "var(--primary2)"};
    color: var(--white);
  }

  .div__options {
    margin: 1rem 0rem;

    .button_project {
      border-radius: var(--border-radius);
      width: auto;
      gap: 0.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba(var(--black_rgb), 0.5);
      padding-right: 0.75rem;

      border: 0;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);

      svg {
        font-size: 1.2rem;
        color: rgba(var(--black_rgb), 0.3);
        /* margin-left: -0.16rem; */
        margin-bottom: 0rem;
      }

      :hover {
        /* box-shadow: 0px 0px 5px rgba(var(--primary2_rgb), 1); */
        border: 2px solid var(--primary2);
        color: var(--primary2);
        svg {
          color: var(--primary2);
        }
      }
    }

    .button_cancel_project {
      border: 0;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);

      :hover {
        /* box-shadow: 0px 0px 5px rgba(var(--primary2_rgb), 1); */
        border: 2px solid var(--delete) !important;
        color: var(--delete) !important;
        svg {
          color: var(--delete) !important;
        }
      }
    }

    .button_payment {
      border-radius: var(--border-radius);
      width: auto;
      /* gap: 0.2rem; */
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba(var(--black_rgb), 0.5);
      padding-right: 0.75rem;

      border: 0;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);

      svg {
        font-size: 1.2rem;
        color: rgba(var(--black_rgb), 0.3);
        /* margin-left: -0.16rem; */
        margin-bottom: 0rem;
      }

      :hover {
        /* box-shadow: 0px 0px 5px rgba(var(--primary2_rgb), 1); */
        border: 2px solid var(--primary2);
        color: var(--primary2);
        svg {
          color: var(--primary2);
        }
      }
    }

    .button_cancel_payment {
      border: 0;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);

      :hover {
        /* box-shadow: 0px 0px 5px rgba(var(--primary2_rgb), 1); */
        border: 2px solid var(--delete) !important;
        color: var(--delete) !important;
        svg {
          color: var(--delete) !important;
        }
      }
    }

    button {
      border-radius: 50%;
      width: 35px;
      margin: 0.2rem;
      height: 35px;
      justify-content: center;
      align-items: center;
      border: 0px solid rgba(var(--black_rgb), 0.3);
      background-color: transparent;

      cursor: pointer;

      border: 0;
      box-shadow: 0.0625rem 0.0625rem 0.3125rem rgba(0, 0, 0, 0.3);

      svg {
        font-size: 1.2rem;
        color: rgba(var(--black_rgb), 0.3);
        /* margin-left: -0.16rem; */
        margin-bottom: -0.2rem;
      }

      :hover {
        /* box-shadow: 0px 0px 5px rgba(var(--primary2_rgb), 1); */
        border: 2px solid var(--primary2);
        svg {
          color: var(--primary2);
        }
      }
    }

    .button_delete {
      :hover {
        /* box-shadow: 0px 0px 5px rgba(var(--primary2_rgb), 1); */
        border: 2px solid var(--delete);
        svg {
          color: var(--delete);
        }
      }
    }
  }

  .div__name {
    padding: 1rem;
    flex: 2;
    text-align: left;
    display: flex;
    flex-direction: column;

    h4 {
      color: ${(props) =>
        props.suspended || (!props.finished && "var(--primary2)")};
    }
    span {
      color: rgba(0, 0, 0, 0.7);
    }
  }

  .div__total_project_power {
    padding: 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h4 {
      margin: 0.5rem;
    }

    .div__sub_total_project_power {
      display: flex;
      flex-direction: column;
      span {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 300;
      }
      p {
        margin: auto;
      }
    }
  }
  .div__status {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex: 3;
    margin-top: 0.5rem;
    margin-bottom: -0.5rem;

    .div__description {
      font-size: 0.7rem;
      padding: 1rem 0.5rem;
      color: rgba(var(--black_rgb), 0.8);
      /* margin-top: -2rem; */
      position: relative;
      top: -2rem;
    }

    .barra_progresso {
      display: flex;
      /* align-items: center; */
      text-align: left;
      gap: 1rem;

      span {
        flex: 1 !important;
        font-size: 0.6rem;
        font-weight: 600;
        margin: 0rem;
        /* min-width: 100px; */
      }
      div {
        flex: 3 !important;
      }
    }

    .div__status_project {
      /* width: 100% !important; */
      flex: 1;
      /* max-width: 350px; */
      background: #e0e0e0;
      height: 7px;

      ::after {
        content: "";
        display: block;

        width: ${(props) =>
          props.finished
            ? 100
            : props.projetct_process / 100 <= 1
            ? props.projetct_process
            : 100}% !important;
        background: ${(props) =>
          props.suspended ? "gray" : "rgb(7, 143, 21)"};

        height: 7px;
      }
    }

    .div__status_process {
      width: 100%;
      /* flex: 1; */
      /* max-width: 350px; */
      background: #e0e0e0;
      height: 7px;

      overflow: hidden;
      margin-top: 2rem;

      ::after {
        content: "";
        display: block;
        width: ${(props) =>
          props.progress <= props.max_process
            ? (props.progress * 100) / props.max_process
            : 100}% !important;
        background: ${(props) =>
          props.progress / props.max_process <= 0.8
            ? "lightgreen"
            : props.progress / props.max_process <= 1
            ? "var(--warn)"
            : "var(--delete)"};

        height: 7px;
      }
    }
  }

  @media screen and (max-width: 675px) {
    flex-direction: column;
    margin-bottom: 1rem;

    .button_payment {
      margin: 0.5rem auto !important;
    }

    .div__name {
      padding: 2rem;
      padding-bottom: 0rem;
      width: 100%;
      flex: 2;
      text-align: center;
      display: flex;
      flex-direction: column;
      span {
        color: rgba(0, 0, 0, 0.7);
      }
    }

    h4 {
      margin-top: 1rem;
    }
    .div__status {
      width: 95%;
    }
  }
`;
