import {
  createContext,
  useEffect,
  useState,
  useCallback,
  useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../services/api';
import { TOKEN_KEY } from '../services/Auth';

export const AuthContext = createContext({});

const adminRoles = ['ADMINISTRADOR', 'GERENTE'];

export function AuthProvider({ children }) {
  const history = useHistory();

  const [user, setUser] = useState();
  const isAuthenticated = !!user?.email;

  const signIn = useCallback(
    async ({ email, password }) => {
      try {
        const response = await api.post('session', {
          email,
          password,
        });

        const { token, user } = response.data;

        const isAdmin = adminRoles.includes(user?.role);
        if (!isAdmin)
          throw new Error('User has no permission to access this page');

        localStorage.setItem(TOKEN_KEY, token);

        setUser(user);

        api.defaults.headers['Authorization'] = `Bearer ${token}`;

        history?.push('/integradores');
      } catch (err) {
        console.log(err);
        toast.warn('Email ou senha inválidos');
      }
    },
    [history]
  );

  const signOut = useCallback(() => {
    localStorage.removeItem(TOKEN_KEY);
    setUser(undefined);
    history.push('/login');
  }, [history]);

  const refreshUser = useCallback(() => {
    const token = localStorage.getItem(TOKEN_KEY);

    if (token) {
      api
        .get('/users/profile')
        .then((response) => {
          const user = response.data;
          setUser(user);
        })
        .catch(() => {
          signOut();
        });
    }
  }, [signOut]);

  useEffect(() => {
    refreshUser();
  }, [refreshUser]);

  return (
    <AuthContext.Provider
      value={{ signIn, signOut, isAuthenticated, user, refreshUser }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuth must be used within an AuthProvider');

  return context;
}
