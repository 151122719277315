import styled from "styled-components";

export const Container = styled.div`
  margin-top: 4rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem 1rem;
`;

export const Page = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  min-height: 200px;

  header {
    width: 100%;
    padding: 1rem 2rem;
    text-align: left;
    font-family: "Poppins", sans-serif;

    h1 {
      font-weight: 800;
      font-size: 1.5rem;
      color: var(--primary2);
      text-transform: uppercase;
      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      opacity: 1;
    }
    span {
      opacity: 0.9;
    }

    ::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      margin-top: 1rem;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
    }

    /* @media screen and (max-width: 675px) {
      text-align: center;
    } */
  }

  .div__deck {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;

    @media screen and (max-width: 675px) {
      grid-template-columns: 1fr;
      /* place-items: center; */
    }
  }

  .div__fieldFilter {
    margin: 0rem 2rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;

    .div__filters {
      display: flex;
      justify-content: flex-start;
      margin: auto 0px;
      width: 100%;
      input,
      select {
        outline-color: var(--primary2);
        padding: 0.75rem 0.5rem;
        border: 1px solid rgba(var(--black_rgb), 0.3);

        margin: 0rem 0.25rem;
        width: 100%;
        /* margin: auto; */
        max-width: 350px;
        z-index: 0;
      }
      @media screen and (max-width: 675px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin-bottom: 1rem;
      }
    }

    .div__order {
      /* background: red; */
      margin: auto;
      display: flex;
      button {
        border: 1px solid rgba(var(--black_rgb), 0.2);
        padding: 0.2rem 0.5rem;
        color: var(--primary2);
        cursor: pointer;

        :hover {
          background-color: var(--primary2) !important;
          color: var(--white);
        }
      }
    }

    .btn__esq {
      background-color: transparent !important;
      border-radius: 0rem;
    }

    .btn__dir {
      background-color: transparent !important;
      border-radius: 0rem;
      border-left: 0px;
    }

    .btn__ativ {
      background-color: var(--primary2) !important;
      color: var(--white) !important;
    }

    .btn_meio {
      background: var(--white);
      border-left: 0px;
    }

    @media screen and (max-width: 675px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .div__conteudo {
    margin: 0rem 2rem;
    margin-bottom: 2rem;
    /* background: red; */

    .div__row {
      display: flex;
      flex-direction: row;

      strong {
        margin-right: 0.5rem;
      }
      small {
        margin: 0rem 0.5rem;
      }
    }

    h4 {
      /* background-color: red; */
      text-transform: uppercase;
      margin: 1rem 0rem;
      font-size: 0.8rem;
      color: rgba(var(--black_rgb), 0.5);
      font-weight: 400;
      width: 100%;
      /* padding: 0rem 2rem; */
      flex: 1;
      /* margin-left: 0rem; */
      text-align: left;

      ::after {
        display: block;
        content: "";
        margin: 0.5rem 0rem;
        border-bottom: 1px dotted rgba(var(--black_rgb), 0.5);
      }
    }

    p {
      flex: 1;
      margin: 0rem;
      font-size: 0.9rem;
    }
    label {
      font-weight: 600;
    }
    .div__oneRow {
      display: flex;
      text-align: left;
      justify-content: space-between;
      /* background: red; */
    }

    @media screen and (max-width: 675px) {
      .div__oneRow {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .div__viewMore {
    border: 1px solid rgba(var(--black_rgb), 0.1);
    text-align: center;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 0rem 0rem 0.5rem 0.5rem;
    cursor: pointer;
    font-weight: 500;
    color: rgba(var(--black_rgb), 0.4);

    :hover {
      box-shadow: 0px -10px 20px rgba(var(--black_rgb), 0.1);
    }
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  /* box-shadow: 0px 0px 10px rgba(var(--black_rgb), 0.1); */
  font-family: "Poppins", sans-serif;
  background-color: ${(props) =>
    props.suspended ? "rgba(var(--black_rgb), 0.05)" : "white"};
  border: ${(props) =>
    props.suspended && "1px solid rgba(var(--black_rgb), 0.05)"};

  h4 {
    margin: 0rem;

    svg {
      font-size: 1rem;
      margin-bottom: -0.17rem;
    }
  }

  span {
    /* margin-bottom: 0.3rem; */
  }

  .div__viewMoreInf {
    width: 100%;
    border: 1px solid rgba(var(--black_rgb), 0.1);
    text-align: center;
    padding: 1rem;
    cursor: pointer;
    font-weight: 500;
    color: rgba(var(--black_rgb), 0.4);

    :hover {
      box-shadow: 0px -10px 20px rgba(var(--black_rgb), 0.1);
    }
  }

  .div__options {
    margin: 1rem 0rem;
    button {
      border-radius: 50%;
      width: 35px;
      margin: 0rem 0.2rem;
      height: 35px;
      justify-content: center;
      align-items: center;
      border: 0px solid rgba(var(--black_rgb), 0.3);
      background-color: transparent;
      border: 2px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;

      svg {
        font-size: 1.2rem;
        color: rgba(var(--black_rgb), 0.3);
        /* margin-left: -0.16rem; */
        margin-bottom: -0.2rem;
      }

      :hover {
        /* box-shadow: 0px 0px 5px rgba(var(--primary2_rgb), 1); */
        border: 2px solid var(--primary2);
        svg {
          color: var(--primary2);
        }
      }
    }

    .button_delete {
      :hover {
        /* box-shadow: 0px 0px 5px rgba(var(--primary2_rgb), 1); */
        border: 2px solid var(--delete);
        svg {
          color: var(--delete);
        }
      }
    }
  }

  .div__name {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-top: 0;
    text-align: left;
    width: 100%;

    h4 {
      color: ${(props) => !props.suspended && "var(--primary2)"};
    }
    span {
      color: rgba(0, 0, 0, 0.7);
    }
  }

  .div__description {
    font-size: 0.7rem;
    padding: 1rem 0.5rem;
    color: rgba(var(--black_rgb), 0.8);
    position: relative;
    top: -2rem;
  }

  @media screen and (max-width: 675px) {
    flex-direction: column;
    margin-bottom: 1rem;

    .div__name {
      padding: 1rem;
      width: 100%;
      flex: 2;
      text-align: center;
      display: flex;
      flex-direction: column;
      span {
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }
`;
