import './App.css';
import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useAuth } from './contexts/AuthContext';
import Login from './pages/public/Login';
import Menu from './components/Menu';
import Dashboard from './pages/private/Dashboard';
import Configuracoes from './pages/private/Configuracoes';
import NovosProjetos from './pages/private/NovosProjetos';
import Integradores from './pages/private/Integradores';
import Users from './pages/private/Users';
import Reports from './pages/private/Reports';
import Projetos from './pages/private/Projetos';
import Orcamentos from './pages/private/Orcamentos';

function App() {
  const { user, signOut } = useAuth();
  const isAdmin = user?.role === 'ADMINISTRADOR';

  const [open, setOpen] = useState(true);

  return (
    <div className="App">
      <Router>
        {user && (
          <Route
            path="/:id2"
            children={
              <Menu
                user={user}
                logoff={() => signOut()}
                abrir={(a) => setOpen(a)}
              />
            }
          />
        )}
        {user ? (
          <div
            style={{
              paddingLeft:
                window.innerWidth < 675 ? '0rem' : open ? '15rem' : '4.5rem',
              paddingTop: window.innerWidth < 675 && '0.75rem',
              transition: '0.25s',
            }}
          >
            <Switch>
              {isAdmin && (
                <Route
                  exact
                  path={'/dashboard'}
                  children={<Dashboard user={user} />}
                />
              )}
              {isAdmin && (
                <Route
                  exact
                  path={'/novos-projetos'}
                  children={<NovosProjetos user={user} />}
                />
              )}

              <Route
                exact
                path={'/integradores'}
                children={<Integradores user={user} />}
              />
              <Route
                exact
                path={'/integradores/projetos/:id'}
                children={<Projetos user={user} />}
              />
              {isAdmin && (
                <Route
                  exact
                  path={'/usuarios'}
                  children={<Users user={user} />}
                />
              )}
              <Route
                exact
                path={'/configuracoes'}
                children={<Configuracoes user={user} />}
              />
              {isAdmin && (
                <Route
                  exact
                  path={'/relatorios'}
                  children={<Reports user={user} />}
                />
              )}
              {isAdmin && (
                <Route
                  exact
                  path={'/orcamentos'}
                  children={<Orcamentos user={user} />}
                />
              )}
              <Route exact path={'*'}>
                <Redirect to="/integradores" />
              </Route>
            </Switch>
          </div>
        ) : (
          <Switch>
            <Route path={'/login'} children={<Login />} />

            <Route path={'*'}>
              <Redirect to="/login" />
            </Route>
          </Switch>
        )}
      </Router>
      <ToastContainer
        style={{ zIndex: '9999999' }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
