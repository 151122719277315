import { ReactNode, useEffect, useState } from 'react';
import Dialogotrocarsenha from '../../../components/Dialogotrocarsenha';
import Dialogoalterardados from '../../../components/Dialogoalterardados';
import { mask } from 'remask';
import Dialogonewuser from '../../../components/Dialogonewuser';
import Printorcamento from '../../../utils/Printorcamento';

import { Container, Page, Card } from './styles';

// icones
import { BsSortAlphaDown, BsSortAlphaUpAlt } from 'react-icons/bs';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';

// confirmacao
import Dialogoconfirmacao from '../../../components/Dialogoconfirmacao';
import { toast } from 'react-toastify';

// backdrop
import Backdrop from '../../../components/Backdrop';

// endpoints
import { GET_BUDGETING, GET_DEALERS } from '../../../services/endpoints';

// tooltip
import Tooltip from '@material-ui/core/Tooltip';

// icones
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PhoneIcon from '@material-ui/icons/Phone';
import api from '../../../services/api';

function Orcamentos(props) {
  const [showback, setShowback] = useState(false);

  const [order, setOrder] = useState('primeiros');
  const [showconf, setShowconf] = useState({
    status: false,
    width: 'sm',
    title: 'Confirmar',
    description: 'Deseja realmente suspender este usuário?',
    btn_conf: 'Sim',
    btn_cancel: 'Não',
    delete: true,
    dados: '',
  });

  const [filter, setFilter] = useState({
    type: '',
    value: '',
    dig: '',
  });

  const [listorcaments, setListorcaments] = useState([]);

  const [listconcess, setListconcess] = useState([]);

  const handleFilter = (dado) => {
    if (filter.type) {
      if (filter.type === 'full_name') {
        if (
          dado.full_name.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
        )
          return true;
      } else if (filter.type === 'dealer') {
        if (filter.value) {
          if (parseInt(dado.dealer_id) === parseInt(filter.value)) return true;
        } else {
          return true;
        }
      } else if (filter.type === 'state') {
        if (filter.value) {
          if (parseInt(dado.dealer_id) === parseInt(filter.value)) return true;
        } else {
          return true;
        }
      }

      //   }
    } else {
      return true;
    }
  };

  const handleFilterValue = (event) => {
    if (filter.type === 'cpf') {
      setFilter({
        ...filter,
        value: mask(event.target.value, '999.999.999-99'),
      });
    } else if (filter.type === 'cnpj') {
      setFilter({
        ...filter,
        value: mask(event.target.value, '99.999.999/9999-99'),
      });
    } else {
      setFilter({ ...filter, value: event.target.value });
    }
  };

  let listorcaments2 = listorcaments.filter(handleFilter).sort((a, b) => {
    if (order === 'AZ') {
      return a.full_name > b.full_name ? 1 : b.full_name > a.full_name ? -1 : 0;
    } else if (order === 'ZA') {
      return a.full_name < b.full_name ? 1 : b.full_name < a.full_name ? -1 : 0;
    } else if (order === 'primeiros') {
      return new Date(b.created_at) - new Date(a.created_at);
    } else if (order === 'ultimos') {
      return new Date(a.created_at) - new Date(b.created_at);
    }
  });

  const deleteOrcamento = async (orcamento_delete) => {
    setShowback(true);

    await api
      .delete(`/budgeting/${orcamento_delete.id}`)
      .then(async (res) => {
        await GET_BUDGETING(setListorcaments, setShowback, 0, 6);
        toast.success(
          `Orçamento de ${showconf.dados.full_name} deletado com sucesso!`
        );
        setShowback(false);
      })
      .catch((error) => {
        toast.error(error.response.data.messages);
      });
  };

  const currency = function (number) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(number);
  };

  useEffect(async () => {
    await GET_BUDGETING(setListorcaments, setShowback, 0, 6);
    await GET_DEALERS(setListconcess, setShowback);
  }, []);

  return (
    <Container>
      <Page>
        <header>
          <h1>Orçamentos realizados</h1>
          <span>
            Gerencie os usuários que terão acesso aos projetos de cada
            Integrador.
          </span>
        </header>
        <content>
          <div className="div__fieldFilter">
            <div className="div__filters">
              <select
                onChange={(event) =>
                  setFilter({
                    ...filter,
                    type: event.target.value,
                    value: '',
                    dig: '',
                  })
                }
                value={filter.type}
                style={{ maxWidth: 200 }}
              >
                <option value="" selected>
                  {' '}
                  Filtro{' '}
                </option>
                <option value="full_name">Nome</option>
                <option value="state">Estado</option>
                <option value="dealer">Concessionária</option>
              </select>

              {/* autocomplete */}

              <input
                placeholder={
                  filter.type === 'full_name'
                    ? 'Digite o nome'
                    : filter.type === 'dealer'
                    ? 'Digite a concessionária'
                    : filter.type === 'cnpj'
                    ? 'Digite o CNPJ'
                    : ' - '
                }
                value={filter.value}
                disabled={!filter.type ? true : false}
                onChange={handleFilterValue}
                style={{
                  display:
                    filter.type === 'full_name' || filter.type === 'cnpj'
                      ? 'block'
                      : 'none',
                }}
              />

              <select
                required
                name="state"
                style={{
                  flex: 0.5,
                  display: filter.type === 'dealer' ? 'block' : 'none',
                }}
                value={filter.value}
                onChange={handleFilterValue}
              >
                <option value="" disabled selected>
                  Escolha a concessionária
                </option>
                {listconcess?.map((dealer, idx) => (
                  <option key={dealer.id} value={dealer.id}>
                    {dealer.name}
                  </option>
                ))}
              </select>

              <select
                required
                name="state"
                style={{
                  flex: 1,
                  display: filter.type === 'state' ? 'block' : 'none',
                }}
                value={filter.value}
                onChange={handleFilterValue}
              >
                <option value="" disabled selected>
                  Escolha o estado
                </option>
                {listconcess?.map((dealer, idx) => (
                  <option key={dealer.id} value={dealer.id}>
                    {dealer.state}
                  </option>
                ))}
              </select>

              {/* fim de autocomplete */}
            </div>
            <div className="div__order">
              <button
                className={order === 'AZ' ? 'btn__esq btn__ativ' : 'btn__esq'}
                onClick={() => (order === 'AZ' ? setOrder('') : setOrder('AZ'))}
              >
                <BsSortAlphaDown style={{ fontSize: '1.4rem' }} />
              </button>
              <button
                className={order === 'ZA' ? 'btn_meio btn__ativ' : 'btn_meio'}
                onClick={() => (order === 'ZA' ? setOrder('') : setOrder('ZA'))}
              >
                <BsSortAlphaUpAlt style={{ fontSize: '1.4rem' }} />
              </button>

              <button
                className={
                  order === 'primeiros' ? 'btn_meio btn__ativ' : 'btn_meio'
                }
                onClick={() =>
                  order === 'primeiros' ? setOrder('') : setOrder('primeiros')
                }
              >
                Recentes
              </button>
              <button
                className={
                  order === 'ultimos' ? 'btn__dir btn__ativ' : 'btn__dir'
                }
                onClick={() =>
                  order === 'ultimos' ? setOrder('') : setOrder('ultimos')
                }
              >
                Antigos
              </button>
            </div>
          </div>

          <div className="div__field_actions">
            <div className="div__fiel_filter"></div>
            <div className="conteudo">
              {/* <button
                type="submit"
                className="btn__finish"
                onClick={() => setNewuser({ ...newuser, open: true })}
              >
                + Novo Usuário
              </button> */}
            </div>
          </div>

          <div className="div__deck">
            <div className="head_list">
              <div className="div__data" style={{ flex: 0.5 }}>
                Data
              </div>
              <div className="div__name" style={{ flex: 3 }}>
                Solicitante
              </div>
              <div className="div__state" style={{ flex: 0.5 }}>
                Estado
              </div>
              <div className="div__dealer" style={{ flex: 1 }}>
                Concessionária
              </div>

              <div className="div__role" style={{ flex: 1 }}>
                Potência
              </div>
              <div className="div__price" style={{ flex: 1 }}>
                Preço
              </div>
              <div
                className="div__email"
                style={{ flex: 1, textAlign: 'center' }}
              >
                Contato
              </div>
              <div>Opções</div>
            </div>
            {listorcaments2.map((usuario, idx) => (
              <Card key={idx}>
                <div className="div__data" style={{ flex: 0.5 }}>
                  <small>
                    {new Date(usuario.created_at).toLocaleDateString('pt-BR')}
                  </small>
                </div>
                <div className="div__name" style={{ flex: 3 }}>
                  {usuario.full_name}
                </div>
                <div className="div__state" style={{ flex: 0.5 }}>
                  {
                    listconcess?.find(
                      (dealer) => dealer.id === usuario.dealer_id
                    )?.state
                  }
                </div>
                <div className="div__dealer" style={{ flex: 1 }}>
                  {
                    listconcess.find(
                      (dealer) => dealer.id === usuario.dealer_id
                    )?.name
                  }
                </div>
                <div className="div__role" style={{ flex: 1 }}>
                  {usuario.power}kWp
                </div>
                <div className="div__price" style={{ flex: 1 }}>
                  {currency(usuario.price)}
                </div>
                <div style={{ display: 'flex', gap: '0.25rem', flex: 1 }}>
                  <Tooltip
                    title={usuario?.phone_number ? 'Whatsapp' : 'Telefone'}
                    style={{
                      pointerEvents: usuario?.phone_number ? '' : 'none',
                      opacity: usuario?.phone_number ? '1' : '0.3',
                    }}
                  >
                    <a
                      href={
                        usuario?.phone_number
                          ? 'https://wa.me/55' +
                            usuario?.phone_number?.replace(/[^0-9]/g, '')
                          : 'tel:55' +
                            usuario?.phone_number?.replace(/[^0-9]/g, '')
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconButton aria-label="whatsapp" className="btn__icon">
                        {usuario?.phone_number ? (
                          <WhatsAppIcon />
                        ) : (
                          <PhoneIcon />
                        )}
                      </IconButton>
                    </a>
                  </Tooltip>

                  <Tooltip title="E-mail">
                    <a
                      href={`mailto:${usuario?.email}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconButton aria-label="email" className="btn__icon">
                        <MailOutlineOutlinedIcon />
                      </IconButton>
                    </a>
                  </Tooltip>
                </div>
                <div className="div__status">
                  {' '}
                  <button
                    className="button_success"
                    onClick={() => {
                      console.log({
                        ...usuario,
                        dealer: listconcess.find(
                          (dealer) => dealer.id === usuario.dealer_id
                        )?.name,
                        state: listconcess.find(
                          (dealer) => dealer.id === usuario.dealer_id
                        )?.state,
                      });
                      Printorcamento({
                        ...usuario,
                        dealer: listconcess.find(
                          (dealer) => dealer.id === usuario.dealer_id
                        )?.name,
                        state: listconcess.find(
                          (dealer) => dealer.id === usuario.dealer_id
                        )?.state,
                      });
                    }}
                  >
                    <GetAppIcon />
                  </button>
                  <button
                    className="button_delete"
                    onClick={() =>
                      setShowconf({
                        status: true,
                        width: 'sm',
                        title: 'Confirmar',
                        description: 'Deseja realmente deletar este orçamento?',
                        btn_conf: 'Sim',
                        btn_cancel: 'Não',
                        delete: true,
                        dados: usuario,
                      })
                    }
                  >
                    <DeleteIcon />
                  </button>
                </div>
              </Card>
            ))}

            {listorcaments2.length === 0 && filter.type ? (
              <div style={{ padding: '1rem' }}>Nenhum orçamento encontrado</div>
            ) : listorcaments2.length > 0 ? (
              ''
            ) : (
              <div style={{ padding: '1rem' }}>Nenhum orçamento realizado</div>
            )}
          </div>
          <br />
        </content>
      </Page>

      {showconf.status && (
        <Dialogoconfirmacao
          status={showconf.status}
          width={showconf.width}
          delete={showconf.delete}
          description={showconf.description}
          fechar={() =>
            setShowconf({
              status: false,
              width: 'sm',
              title: 'Confirmar',
              description: 'Deseja realmente suspender este projeto?',
              btn_conf: 'Sim',
              btn_cancel: 'Não',
              delete: true,
              dados: '',
            })
          }
          confirmar={() => {
            deleteOrcamento(showconf.dados);
          }}
        />
      )}
      {showback && <Backdrop />}
    </Container>
  );
}

export default Orcamentos;
