import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  font-family: "Poppins", sans-serif;

  /* button{
    display: ${(props) => props.suspended && "none"};
  } */

  h1 {
    /* text-transform: uppercase; */
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0rem;
    color: var(--primary2);
  }
  span {
    font-size: 0.9rem;
  }
  h4 {
    /* background-color: red; */
    text-transform: uppercase;
    margin: 1rem 0rem;
    font-size: 0.8rem;
    color: rgba(var(--black_rgb), 0.5);
    font-weight: 400;
    width: 100%;
    /* padding: 0rem 2rem; */
    flex: 1;
    /* margin-left: 0rem; */
    text-align: left;

    ::after {
      display: block;
      content: "";
      margin: 0.5rem 0rem;
      border-bottom: 1px dotted rgba(var(--black_rgb), 0.5);
    }
  }

  p {
    flex: 1;
    margin: 0rem;
    font-size: 0.9rem;
  }
  label {
    font-weight: 600;
  }
  .div__oneRow {
    display: flex;
  }

  input,
  select {
    outline-color: var(--primary2);
    padding: 0.75rem 0.5rem;
    border: 1px solid rgba(var(--black_rgb), 0.3);

    margin: 0.5rem 0.25rem;
    width: 100%;
    z-index: 0;
  }

  .div__listpendencias {
    display: flex;
    flex-direction: column;
    align-items: stretch !important;
    gap: 1rem;
    margin-top: 1rem;

    .div__pendencias {
      display: flex;

      .icone_warn {
        color: var(--warn);
      }
      .icone_success {
        color: var(--success);
        font-size: 1.4rem;
      }
      .icone_suspend {
        color: var(--delete);
        font-size: 1.4rem;
      }

      .div__info {
        margin: 0.15rem 0.2rem;

        ul {
          margin: 0rem;
          padding: auto;
          list-style-type: none;

          ::before {
            display: block;
            content: "";
            margin-top: 0.25rem;
            margin-left: -2.5rem;
            max-width: 200px;
            border-top: 1px dotted rgba(var(--black_rgb), 0.5);
          }

          li {
            margin: 0rem;
            margin-left: -2rem;
            padding: auto;
            font-size: 0.8rem;
          }
        }

        h5 {
          margin: 0rem;
          span {
            font-weight: 400;
            border-radius: var(--border-radius);
            background-color: rgba(var(--black_rgb), 0.1);
            border: 1px solid rgba(var(--black_rgb), 0.1);
            font-size: 0.7rem;
            padding: 0rem 0.5rem;
            text-transform: uppercase;
          }
        }
        .title_suspend {
          color: var(--delete) !important;
        }
      }
    }

    .div__nada {
      margin: auto;
    }

    button {
      border-radius: 50%;
      width: 35px;
      margin: 0rem 0.2rem;
      height: 35px;
      justify-content: center;
      align-items: center;
      border: 0px solid rgba(var(--black_rgb), 0.3);
      background-color: transparent;
      border: 2px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;

      svg {
        font-size: 1.2rem;
        color: rgba(var(--black_rgb), 0.3);
        /* margin-left: -0.16rem; */
        margin-bottom: -0.2rem;
      }

      :hover {
        /* box-shadow: 0px 0px 5px rgba(var(--primary2_rgb), 1); */
        border: 2px solid var(--primary2);
        svg {
          color: var(--primary2);
        }
      }
    }

    .div__options {
      display: flex;

      button {
        border-radius: 50%;
        width: 35px;
        margin: 0rem 0.2rem;
        height: 35px;
        border: 2px solid var(--primary2);
        cursor: pointer;

        svg {
          font-size: 1.2rem;
          color: var(--primary2);
          margin-bottom: -0.2rem;
        }

        :hover {
          border: 2px solid var(--primary2);
          background-color: var(--primary2);
          svg {
            color: var(--white);
          }
        }
      }
    }

    .btn__retornar {
      font-family: "Poppins", sans-serif;
      text-transform: uppercase;
      width: 100%;
      max-width: 180px;
      height: 35px;
      background-color: transparent;
      border: 1px solid var(--primary2);
      border-radius: var(--border-radius);
      color: var(--primary2);
      margin-right: 0.75rem;
      cursor: pointer;
      :hover,
      :focus {
        box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.2);
      }
    }
    .btn__register {
      font-family: "Poppins", sans-serif;
      text-transform: uppercase;
      width: 320px;
      /* height: 45px; */
      background-color: var(--primary2);
      border: 0px;
      border-radius: var(--border-radius);
      color: var(--white);
      cursor: pointer;
      :hover,
      :focus {
        box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.4);
        filter: brightness(1.4);
      }
    }

    @media screen and (max-width: 675px) {
      flex-direction: column-reverse;
      flex: 1;

      .btn__retornar,
      .btn__register {
        /* margin: auto; */
        width: 100% !important;
        max-width: none;
        margin: 0.5rem;
        font-size: 1rem;
      }
    }
  }

  input[type="file"] {
    display: none;
  }

  .div__dataArchive {
    svg {
      font-size: 4.5rem;
    }

    display: flex;
    flex-direction: row;

    .div__info {
      display: flex;
      flex-direction: column;

      p {
        margin: 0;
        text-align: left;
      }
    }
  }

  .div__remove_field_arch {
    .btn__remove_field {
      background: var(--delete);
      color: var(--white);
      border: 0rem;
      max-width: 250px;
      float: right;
      padding: 0.5rem 1rem;

      margin: 0rem 0.5rem;
      margin-bottom: -2rem;
      cursor: pointer;

      :hover {
        filter: brightness(1.1);
      }
    }

    .btn__remove_field_disabled {
      pointer-events: none;
      background: lightgray;
      color: gray;
    }
  }

  .div__newfieldarchive {
    /* height: 5rem; */
    display: flex;
    background: rgba(var(--black_rgb), 0.05);
    margin: 2rem 0rem;
    padding: 0.5rem;

    .div__newfieldarchive_in {
      display: flex;
      flex-direction: row;
      flex: 1;
      padding: 1.4rem 1rem;
      justify-content: space-between;
      border: 2px dotted rgba(var(--black_rgb), 0.25);
      cursor: pointer;

      .div__dataArchive {
        margin-top: 0.4rem;
        color: rgba(var(--black_rgb), 0.8);
        svg {
          color: rgba(var(--black_rgb), 0.8);
        }
        .div__info {
        }
      }

      .div__env_archive {
        margin: auto 1rem;
        div {
          width: 240px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-size: 0.8rem;

          svg {
            margin: auto;
            /* margin-right: 0rem; */
            font-size: 2rem;
          }
        }
      }

      :hover {
        border: 2px dotted var(--primary2);
        color: var(--primary2) !important;
        svg,
        p {
          color: var(--primary2);
        }
      }
    }
  }

  .div__selectedFieldArchive {
    background-color: rgba(var(--primary_rgb), 0.15);
    color: var(--primary2);
    p {
      color: var(--primary2) !important;
    }
    div {
      color: var(--primary2) !important;
      svg {
        color: var(--primary2) !important;
      }
    }
    .div__newfieldarchive_in {
      border: 2px dotted rgba(var(--primary2_rgb), 0.2);
    }

    :hover {
      background-color: rgba(var(--delete_rgb), 0.05);

      .div__newfieldarchive_in {
        border: 2px dotted var(--delete);
      }

      p {
        color: var(--delete) !important;
      }
      div {
        color: var(--delete) !important;
        svg {
          color: var(--delete) !important;
        }
      }
    }
  }

  .div__multiColumn {
    display: flex;
    padding: 0rem 2rem;
    margin-top: 0.5rem;
  }

  .div__moreFiles {
    text-align: right;

    .btn__newFile {
      font-family: "Poppins", sans-serif;
      text-transform: uppercase;

      padding: 0.5rem 1rem;
      background-color: var(--primary2);
      border: 0px;
      border-radius: var(--border-radius);
      color: var(--white);
      margin-bottom: 0.5rem;
      /* margin-bottom: 0.75rem; */
      cursor: pointer;
      :hover,
      :focus {
        box-shadow: 0px 0px 10px rgba(var(--black_rgb), 0.4);
        /* filter: brightness(1.4); */
      }
    }
  }

  .div__viewMore {
    border: 1px solid rgba(var(--black_rgb), 0.1);
    text-align: center;
    padding: 1rem;

    cursor: pointer;
    font-weight: 500;
    color: rgba(var(--black_rgb), 0.4);

    :hover {
      box-shadow: 0px -10px 20px rgba(var(--black_rgb), 0.1);
    }
  }

  .div__icon {
    svg {
      font-size: 3.5rem;
    }
  }
  .div__rowArchive {
    margin: 0.1rem 0rem;
    padding: 0.1rem 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(var(--black_rgb), 0.1);
    font-family: "Poppins", sans-serif;

    .div__data {
      p {
        font-size: 0.7rem;
      }
    }
  }
  .div__options {
    margin: 1rem 0rem;
    text-align: right;
    button {
      border-radius: 50%;
      width: 35px;
      margin: 0rem 0.2rem;
      height: 35px;
      justify-content: center;
      align-items: center;
      border: 0px solid rgba(var(--black_rgb), 0.3);
      background-color: transparent;
      border: 2px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;

      svg {
        font-size: 1.2rem;
        color: rgba(var(--black_rgb), 0.3);
        /* margin-left: -0.16rem; */
        margin-bottom: -0.2rem;
      }

      :hover {
        /* box-shadow: 0px 0px 5px rgba(var(--primary2_rgb), 1); */
        border: 2px solid var(--primary2);
        svg {
          color: var(--primary2);
        }
      }
    }

    .button_delete {
      :hover {
        /* box-shadow: 0px 0px 5px rgba(var(--primary2_rgb), 1); */
        border: 2px solid var(--delete);
        svg {
          color: var(--delete);
        }
      }
    }
  }

  @media screen and (max-width: 675px) {
    .div__oneRow {
      display: flex;
      flex-direction: column;
    }

    .div__newfieldarchive,
    .div__newfieldarchive_in {
      flex-direction: column !important;
      padding: 0.5rem !important;
      .div__env_archive {
        margin: 1rem auto !important;
      }
    }

    .div__del_archive,
    .div__archive {
      margin: 1rem 0rem;
    }

    .div__options {
      flex-direction: column;
      margin-bottom: 1rem;
    }
  }
`;

export const FielButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .btn__retornar {
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    width: 100%;
    max-width: 180px;
    height: 35px;
    background-color: transparent;
    border: 1px solid var(--primary2);
    border-radius: var(--border-radius);
    color: var(--primary2);
    /* margin-top: 1rem; */
    margin-right: 0.75rem;
    cursor: pointer;
    :hover,
    :focus {
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.2);
    }
  }
  .btn__register {
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;

    width: 320px;
    height: 45px;
    background-color: var(--primary2);
    border: 0px;
    border-radius: var(--border-radius);
    color: var(--white);
    /* margin-top: 1rem; */
    /* margin-bottom: 0.75rem; */
    cursor: pointer;
    :hover,
    :focus {
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.4);
      filter: brightness(1.4);
    }
  }

  @media screen and (max-width: 675px) {
    flex-direction: column-reverse;
    flex: 1;

    .btn__retornar,
    .btn__register {
      /* margin: auto; */
      width: 100% !important;
      max-width: none;
      margin: 0.5rem;
      font-size: 1rem;
    }
  }
`;
